.ql-hidden {
  display: none; }

.ql-editor {
  padding: 20px 0;
  min-height: 120px;
  line-height: 22px;
  max-height: 35vh; }
  @media (max-width: 767.8px) {
    .ql-editor {
      padding: 0; } }
  .ql-editor:focus {
    outline: none; }

.ql-editor ul > li {
  position: relative; }

.ql-editor ul > li:not(.ql-direction-rtl)::before {
  margin-left: -1.4em;
  font-size: 26px;
  line-height: 20px;
  position: absolute; }

.thread .draft-wrapper .ql-editor {
  height: 320px; }
