header#header {
  position: relative; }
  header#header .menu-toggle {
    width: 40px;
    order: 2;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px; }
  header#header .brand {
    display: flex;
    justify-content: center; }
    @media (min-width: 768px) {
      header#header .brand {
        float: left;
        margin: 0; } }
    header#header .brand img {
      display: block;
      width: auto;
      width: 150px; }
  header#header #user-menu {
    display: flex;
    justify-content: space-between; }
    @media (min-width: 768px) {
      header#header #user-menu {
        position: static; } }
  header#header nav > ul.nav {
    padding-left: 0;
    margin: 0; }
    header#header nav > ul.nav > li {
      list-style: none;
      margin-left: 0;
      display: inline-block; }
  header#header .avatar {
    float: right; }
    header#header .avatar .photo {
      border-radius: 50%;
      border: 1px solid; }
      header#header .avatar .photo img {
        border-radius: 50%; }
  header#header .greeting-nav-wrapper {
    float: left; }
  header#header:after {
    content: ' ';
    display: table;
    clear: both; }
  header#header .menu-bars {
    width: 24px; }
    header#header .menu-bars .menu-bar {
      background: #fff;
      height: 2px; }
      header#header .menu-bars .menu-bar + .menu-bar {
        margin-top: 6px; }
