@charset "UTF-8";
@-webkit-keyframes bounce {
  0% {
    bottom: 0px; }
  25%, 75% {
    bottom: 2px; }
  50% {
    bottom: 5px; }
  100% {
    bottom: 0; } }

.thread .nav {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

.ql-editor.ql-blank:before {
  line-height: 2rem;
  left: 0 !important; }

.ql-wrapper p, .thread .question-body p, .thread .body p,
.ql-wrapper ol,
.thread .question-body ol,
.thread .body ol,
.ql-wrapper ul,
.thread .question-body ul,
.thread .body ul,
.ql-wrapper pre,
.thread .question-body pre,
.thread .body pre,
.ql-wrapper blockquote,
.thread .question-body blockquote,
.thread .body blockquote,
.ql-wrapper h1,
.thread .question-body h1,
.thread .body h1,
.ql-wrapper h2,
.thread .question-body h2,
.thread .body h2,
.ql-wrapper h3,
.thread .question-body h3,
.thread .body h3,
.ql-wrapper h4,
.thread .question-body h4,
.thread .body h4,
.ql-wrapper h5,
.thread .question-body h5,
.thread .body h5,
.ql-wrapper h6,
.thread .question-body h6,
.thread .body h6 {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9; }


.ql-wrapper ol,
.thread .question-body ol,
.thread .body ol,
.ql-wrapper ul,
.thread .question-body ul,
.thread .body ul {
  padding-left: 1.5em;
  margin-bottom: 10px; }

.ql-wrapper ol > li, .thread .question-body ol > li, .thread .body ol > li,
.ql-wrapper ul > li,
.thread .question-body ul > li,
.thread .body ul > li {
  list-style-type: none; }

.ql-wrapper ul > li::before, .thread .question-body ul > li::before, .thread .body ul > li::before {
  content: '\2022'; }

.ql-wrapper ul[data-checked=true], .thread .question-body ul[data-checked=true], .thread .body ul[data-checked=true],
.ql-wrapper ul[data-checked=false],
.thread .question-body ul[data-checked=false],
.thread .body ul[data-checked=false] {
  pointer-events: none; }

.ql-wrapper ul[data-checked=true] > li *, .thread .question-body ul[data-checked=true] > li *, .thread .body ul[data-checked=true] > li *,
.ql-wrapper ul[data-checked=false] > li *,
.thread .question-body ul[data-checked=false] > li *,
.thread .body ul[data-checked=false] > li * {
  pointer-events: all; }

.ql-wrapper ul[data-checked=true] > li::before, .thread .question-body ul[data-checked=true] > li::before, .thread .body ul[data-checked=true] > li::before,
.ql-wrapper ul[data-checked=false] > li::before,
.thread .question-body ul[data-checked=false] > li::before,
.thread .body ul[data-checked=false] > li::before {
  color: #777;
  cursor: pointer;
  pointer-events: all; }

.ql-wrapper ul[data-checked=true] > li::before, .thread .question-body ul[data-checked=true] > li::before, .thread .body ul[data-checked=true] > li::before {
  content: '\2611'; }


.ql-wrapper ul[data-checked=false] > li::before,
.thread .question-body ul[data-checked=false] > li::before,
.thread .body ul[data-checked=false] > li::before {
  content: '\2610'; }

.ql-wrapper li::before, .thread .question-body li::before, .thread .body li::before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em; }

.ql-wrapper li:not(.ql-direction-rtl)::before, .thread .question-body li:not(.ql-direction-rtl)::before, .thread .body li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right; }

.ql-wrapper li.ql-direction-rtl::before, .thread .question-body li.ql-direction-rtl::before, .thread .body li.ql-direction-rtl::before {
  margin-left: 0.3em;
  margin-right: -1.5em; }

.ql-wrapper ol li:not(.ql-direction-rtl), .thread .question-body ol li:not(.ql-direction-rtl), .thread .body ol li:not(.ql-direction-rtl),
.ql-wrapper ul li:not(.ql-direction-rtl),
.thread .question-body ul li:not(.ql-direction-rtl),
.thread .body ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em; }

.ql-wrapper ol li.ql-direction-rtl, .thread .question-body ol li.ql-direction-rtl, .thread .body ol li.ql-direction-rtl,
.ql-wrapper ul li.ql-direction-rtl,
.thread .question-body ul li.ql-direction-rtl,
.thread .body ul li.ql-direction-rtl {
  padding-right: 1.5em; }

.ql-wrapper ol li, .thread .question-body ol li, .thread .body ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0; }

.ql-wrapper ol li:before, .thread .question-body ol li:before, .thread .body ol li:before {
  content: counter(list-0, decimal) ". "; }

.ql-wrapper ol li.ql-indent-1, .thread .question-body ol li.ql-indent-1, .thread .body ol li.ql-indent-1 {
  counter-increment: list-1; }

.ql-wrapper ol li.ql-indent-1:before, .thread .question-body ol li.ql-indent-1:before, .thread .body ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) ". "; }

.ql-wrapper ol li.ql-indent-1, .thread .question-body ol li.ql-indent-1, .thread .body ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9; }

.ql-wrapper ol li.ql-indent-2, .thread .question-body ol li.ql-indent-2, .thread .body ol li.ql-indent-2 {
  counter-increment: list-2; }

.ql-wrapper ol li.ql-indent-2:before, .thread .question-body ol li.ql-indent-2:before, .thread .body ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) ". "; }

.ql-wrapper ol li.ql-indent-2, .thread .question-body ol li.ql-indent-2, .thread .body ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9; }

.ql-wrapper ol li.ql-indent-3, .thread .question-body ol li.ql-indent-3, .thread .body ol li.ql-indent-3 {
  counter-increment: list-3; }

.ql-wrapper ol li.ql-indent-3:before, .thread .question-body ol li.ql-indent-3:before, .thread .body ol li.ql-indent-3:before {
  content: counter(list-3, decimal) ". "; }

.ql-wrapper ol li.ql-indent-3, .thread .question-body ol li.ql-indent-3, .thread .body ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9; }

.ql-wrapper ol li.ql-indent-4, .thread .question-body ol li.ql-indent-4, .thread .body ol li.ql-indent-4 {
  counter-increment: list-4; }

.ql-wrapper ol li.ql-indent-4:before, .thread .question-body ol li.ql-indent-4:before, .thread .body ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) ". "; }

.ql-wrapper ol li.ql-indent-4, .thread .question-body ol li.ql-indent-4, .thread .body ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9; }

.ql-wrapper ol li.ql-indent-5, .thread .question-body ol li.ql-indent-5, .thread .body ol li.ql-indent-5 {
  counter-increment: list-5; }

.ql-wrapper ol li.ql-indent-5:before, .thread .question-body ol li.ql-indent-5:before, .thread .body ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) ". "; }

.ql-wrapper ol li.ql-indent-5, .thread .question-body ol li.ql-indent-5, .thread .body ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9; }

.ql-wrapper ol li.ql-indent-6, .thread .question-body ol li.ql-indent-6, .thread .body ol li.ql-indent-6 {
  counter-increment: list-6; }

.ql-wrapper ol li.ql-indent-6:before, .thread .question-body ol li.ql-indent-6:before, .thread .body ol li.ql-indent-6:before {
  content: counter(list-6, decimal) ". "; }

.ql-wrapper ol li.ql-indent-6, .thread .question-body ol li.ql-indent-6, .thread .body ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9; }

.ql-wrapper ol li.ql-indent-7, .thread .question-body ol li.ql-indent-7, .thread .body ol li.ql-indent-7 {
  counter-increment: list-7; }

.ql-wrapper ol li.ql-indent-7:before, .thread .question-body ol li.ql-indent-7:before, .thread .body ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) ". "; }

.ql-wrapper ol li.ql-indent-7, .thread .question-body ol li.ql-indent-7, .thread .body ol li.ql-indent-7 {
  counter-reset: list-8 list-9; }

.ql-wrapper ol li.ql-indent-8, .thread .question-body ol li.ql-indent-8, .thread .body ol li.ql-indent-8 {
  counter-increment: list-8; }

.ql-wrapper ol li.ql-indent-8:before, .thread .question-body ol li.ql-indent-8:before, .thread .body ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) ". "; }

.ql-wrapper ol li.ql-indent-8, .thread .question-body ol li.ql-indent-8, .thread .body ol li.ql-indent-8 {
  counter-reset: list-9; }

.ql-wrapper ol li.ql-indent-9, .thread .question-body ol li.ql-indent-9, .thread .body ol li.ql-indent-9 {
  counter-increment: list-9; }

.ql-wrapper ol li.ql-indent-9:before, .thread .question-body ol li.ql-indent-9:before, .thread .body ol li.ql-indent-9:before {
  content: counter(list-9, decimal) ". "; }

.ql-wrapper .ql-indent-1:not(.ql-direction-rtl), .thread .question-body .ql-indent-1:not(.ql-direction-rtl), .thread .body .ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 3em; }

.ql-wrapper li.ql-indent-1:not(.ql-direction-rtl), .thread .question-body li.ql-indent-1:not(.ql-direction-rtl), .thread .body li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 4.5em; }

.ql-wrapper .ql-indent-1.ql-direction-rtl.ql-align-right, .thread .question-body .ql-indent-1.ql-direction-rtl.ql-align-right, .thread .body .ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 3em; }

.ql-wrapper li.ql-indent-1.ql-direction-rtl.ql-align-right, .thread .question-body li.ql-indent-1.ql-direction-rtl.ql-align-right, .thread .body li.ql-indent-1.ql-direction-rtl.ql-align-right {
  padding-right: 4.5em; }

.ql-wrapper .ql-indent-2:not(.ql-direction-rtl), .thread .question-body .ql-indent-2:not(.ql-direction-rtl), .thread .body .ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 6em; }

.ql-wrapper li.ql-indent-2:not(.ql-direction-rtl), .thread .question-body li.ql-indent-2:not(.ql-direction-rtl), .thread .body li.ql-indent-2:not(.ql-direction-rtl) {
  padding-left: 7.5em; }

.ql-wrapper .ql-indent-2.ql-direction-rtl.ql-align-right, .thread .question-body .ql-indent-2.ql-direction-rtl.ql-align-right, .thread .body .ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 6em; }

.ql-wrapper li.ql-indent-2.ql-direction-rtl.ql-align-right, .thread .question-body li.ql-indent-2.ql-direction-rtl.ql-align-right, .thread .body li.ql-indent-2.ql-direction-rtl.ql-align-right {
  padding-right: 7.5em; }

.ql-wrapper .ql-indent-3:not(.ql-direction-rtl), .thread .question-body .ql-indent-3:not(.ql-direction-rtl), .thread .body .ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 9em; }

.ql-wrapper li.ql-indent-3:not(.ql-direction-rtl), .thread .question-body li.ql-indent-3:not(.ql-direction-rtl), .thread .body li.ql-indent-3:not(.ql-direction-rtl) {
  padding-left: 10.5em; }

.ql-wrapper .ql-indent-3.ql-direction-rtl.ql-align-right, .thread .question-body .ql-indent-3.ql-direction-rtl.ql-align-right, .thread .body .ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 9em; }

.ql-wrapper li.ql-indent-3.ql-direction-rtl.ql-align-right, .thread .question-body li.ql-indent-3.ql-direction-rtl.ql-align-right, .thread .body li.ql-indent-3.ql-direction-rtl.ql-align-right {
  padding-right: 10.5em; }

.ql-wrapper .ql-indent-4:not(.ql-direction-rtl), .thread .question-body .ql-indent-4:not(.ql-direction-rtl), .thread .body .ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 12em; }

.ql-wrapper li.ql-indent-4:not(.ql-direction-rtl), .thread .question-body li.ql-indent-4:not(.ql-direction-rtl), .thread .body li.ql-indent-4:not(.ql-direction-rtl) {
  padding-left: 13.5em; }

.ql-wrapper .ql-indent-4.ql-direction-rtl.ql-align-right, .thread .question-body .ql-indent-4.ql-direction-rtl.ql-align-right, .thread .body .ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 12em; }

.ql-wrapper li.ql-indent-4.ql-direction-rtl.ql-align-right, .thread .question-body li.ql-indent-4.ql-direction-rtl.ql-align-right, .thread .body li.ql-indent-4.ql-direction-rtl.ql-align-right {
  padding-right: 13.5em; }

.ql-wrapper .ql-indent-5:not(.ql-direction-rtl), .thread .question-body .ql-indent-5:not(.ql-direction-rtl), .thread .body .ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 15em; }

.ql-wrapper li.ql-indent-5:not(.ql-direction-rtl), .thread .question-body li.ql-indent-5:not(.ql-direction-rtl), .thread .body li.ql-indent-5:not(.ql-direction-rtl) {
  padding-left: 16.5em; }

.ql-wrapper .ql-indent-5.ql-direction-rtl.ql-align-right, .thread .question-body .ql-indent-5.ql-direction-rtl.ql-align-right, .thread .body .ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 15em; }

.ql-wrapper li.ql-indent-5.ql-direction-rtl.ql-align-right, .thread .question-body li.ql-indent-5.ql-direction-rtl.ql-align-right, .thread .body li.ql-indent-5.ql-direction-rtl.ql-align-right {
  padding-right: 16.5em; }

.ql-wrapper .ql-indent-6:not(.ql-direction-rtl), .thread .question-body .ql-indent-6:not(.ql-direction-rtl), .thread .body .ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 18em; }

.ql-wrapper li.ql-indent-6:not(.ql-direction-rtl), .thread .question-body li.ql-indent-6:not(.ql-direction-rtl), .thread .body li.ql-indent-6:not(.ql-direction-rtl) {
  padding-left: 19.5em; }

.ql-wrapper .ql-indent-6.ql-direction-rtl.ql-align-right, .thread .question-body .ql-indent-6.ql-direction-rtl.ql-align-right, .thread .body .ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 18em; }

.ql-wrapper li.ql-indent-6.ql-direction-rtl.ql-align-right, .thread .question-body li.ql-indent-6.ql-direction-rtl.ql-align-right, .thread .body li.ql-indent-6.ql-direction-rtl.ql-align-right {
  padding-right: 19.5em; }

.ql-wrapper .ql-indent-7:not(.ql-direction-rtl), .thread .question-body .ql-indent-7:not(.ql-direction-rtl), .thread .body .ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 21em; }

.ql-wrapper li.ql-indent-7:not(.ql-direction-rtl), .thread .question-body li.ql-indent-7:not(.ql-direction-rtl), .thread .body li.ql-indent-7:not(.ql-direction-rtl) {
  padding-left: 22.5em; }

.ql-wrapper .ql-indent-7.ql-direction-rtl.ql-align-right, .thread .question-body .ql-indent-7.ql-direction-rtl.ql-align-right, .thread .body .ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 21em; }

.ql-wrapper li.ql-indent-7.ql-direction-rtl.ql-align-right, .thread .question-body li.ql-indent-7.ql-direction-rtl.ql-align-right, .thread .body li.ql-indent-7.ql-direction-rtl.ql-align-right {
  padding-right: 22.5em; }

.ql-wrapper .ql-indent-8:not(.ql-direction-rtl), .thread .question-body .ql-indent-8:not(.ql-direction-rtl), .thread .body .ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 24em; }

.ql-wrapper li.ql-indent-8:not(.ql-direction-rtl), .thread .question-body li.ql-indent-8:not(.ql-direction-rtl), .thread .body li.ql-indent-8:not(.ql-direction-rtl) {
  padding-left: 25.5em; }

.ql-wrapper .ql-indent-8.ql-direction-rtl.ql-align-right, .thread .question-body .ql-indent-8.ql-direction-rtl.ql-align-right, .thread .body .ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 24em; }

.ql-wrapper li.ql-indent-8.ql-direction-rtl.ql-align-right, .thread .question-body li.ql-indent-8.ql-direction-rtl.ql-align-right, .thread .body li.ql-indent-8.ql-direction-rtl.ql-align-right {
  padding-right: 25.5em; }

.ql-wrapper .ql-indent-9:not(.ql-direction-rtl), .thread .question-body .ql-indent-9:not(.ql-direction-rtl), .thread .body .ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 27em; }

.ql-wrapper li.ql-indent-9:not(.ql-direction-rtl), .thread .question-body li.ql-indent-9:not(.ql-direction-rtl), .thread .body li.ql-indent-9:not(.ql-direction-rtl) {
  padding-left: 28.5em; }

.ql-wrapper .ql-indent-9.ql-direction-rtl.ql-align-right, .thread .question-body .ql-indent-9.ql-direction-rtl.ql-align-right, .thread .body .ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 27em; }

.ql-wrapper li.ql-indent-9.ql-direction-rtl.ql-align-right, .thread .question-body li.ql-indent-9.ql-direction-rtl.ql-align-right, .thread .body li.ql-indent-9.ql-direction-rtl.ql-align-right {
  padding-right: 28.5em; }

@font-face {
  font-family: 'AnswerBar';
  src: url("../fonts/AnswerBar.ttf?yc4cek") format("truetype"), url("../fonts/AnswerBar.woff?yc4cek") format("woff"), url("../fonts/AnswerBar.svg?yc4cek#AnswerBar") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="answerbar-"], [class*=" answerbar-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'AnswerBar' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.answerbar-angle-down:before {
  content: ""; }

.answerbar-angle-left:before {
  content: ""; }

.answerbar-angle-right:before {
  content: ""; }

.answerbar-angle-up:before {
  content: ""; }

.answerbar-checkmark:before {
  content: ""; }

.answerbar-close:before {
  content: ""; }

.answerbar-plus:before {
  content: ""; }

.answerbar-box-open:before {
  content: ""; }

.answerbar-caret-down:before {
  content: ""; }

.answerbar-caret-up:before {
  content: ""; }

.answerbar-collapse:before {
  content: ""; }

.answerbar-expand:before {
  content: ""; }

.answerbar-share-box:before {
  content: ""; }

.answerbar-share:before {
  content: ""; }

.answerbar-trash:before {
  content: ""; }

.app-container.in-modal-view .app-header {
  -webkit-filter: blur(15px);
  -moz-filter: blur(15px);
  -o-filter: blur(15px);
  -ms-filter: blur(15px);
  filter: blur(15px); }

.app-container.in-modal-view .app-background:before {
  opacity: 1;
  transition: all 0.4s;
  transform: scale(0.95); }

.app-container.in-modal-view .app-header {
  transform: scale(0.95); }

.app-container.in-menu-view .app-header {
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  filter: blur(8px); }

.app-container.in-menu-view .app-view {
  overflow: hidden;
  max-height: 100vh;
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  filter: blur(8px); }

.app-container.in-menu-view .app-header {
  transform: scale(0.95); }

.modal-wrapper.active .menu-body,
.modal-wrapper.active .modal-body {
  opacity: 1;
  top: 0; }

.modal-wrapper.active .menu-background {
  opacity: 0.4; }

.app-background:before,
.app-header,
.app-view,
.app-footer {
  transition: filter 0.4s, transform 0.4s; }

.app-background-wrapper {
  position: absolute;
  z-index: 0;
  height: 550px;
  width: 100vw;
  overflow: hidden;
  border: 5px solid #0b2733; }
  .app-background-wrapper:after {
    content: '';
    display: block;
    background: #0b2733;
    background: linear-gradient(0deg, #0b2733 0%, rgba(11, 39, 51, 0) 100%);
    height: 100px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0; }

.app-background {
  background: url("../images/bg.jpg") no-repeat 50% 0% #08202c;
  background-size: cover;
  position: absolute;
  z-index: 0;
  height: 550px;
  width: 100vw;
  transform: scale(1.1);
  transition: all 0.4s; }
  .app-background:before {
    content: ' ';
    display: block;
    background: url("../images/bg-blurred.jpg") no-repeat 50% 0% #08202c;
    background-size: cover;
    position: fixed;
    z-index: 1;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    transition: all 0.4s; }

.app-viewport {
  min-height: 100vh; }

.app-header {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100px; }

.in-modal-view .app-footer {
  display: none; }

.app-view {
  padding-top: env(safe-area-inset-top); }

.menu-header,
.modal-header {
  position: fixed;
  left: 20px;
  right: 20px;
  top: 20px;
  padding: 0;
  z-index: 1001;
  border-bottom: 0 none;
  justify-content: flex-end; }

.menu-view,
.modal-view {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  z-index: 1000; }
  .menu-view > .inner,
  .modal-view > .inner {
    padding: 80px 10px 20px;
    min-height: 100vh;
    border: 5px solid #0b2733; }

.menu-view {
  background: #0b2733;
  color: #fff;
  font-family: "Open Sans", sans-serif; }
  .menu-view .menu-body ul.nav li a {
    color: #fff;
    font-size: 17px;
    line-height: 24px;
    padding: 12px 20px;
    font-weight: 600; }
  .menu-view > .inner {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0; }

.menu-background {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 800;
  background: url("../images/bg-blurred.jpg") no-repeat 50% 0 transparent;
  background-size: cover;
  border: 5px solid #0b2733;
  opacity: 0;
  transition: opacity 0.4s;
  pointer-events: unset; }

.menu-background-close {
  flex-grow: 1; }

.modal-back-button {
  text-align: center;
  cursor: pointer;
  font-size: 18px;
  line-height: 18px;
  white-space: nowrap; }
  .modal-back-button i {
    color: #f8e379;
    font-size: 18px;
    line-height: 18px;
    display: block;
    float: left;
    margin-right: 4px; }
  .modal-back-button .text-label {
    color: #fff; }

.menu-close-button,
.modal-print-button,
.modal-close-button {
  cursor: pointer;
  text-align: center; }
  .menu-close-button i.material-icons,
  .menu-close-button .close-bars,
  .modal-print-button i.material-icons,
  .modal-print-button .close-bars,
  .modal-close-button i.material-icons,
  .modal-close-button .close-bars {
    height: 28px;
    margin-bottom: 4px; }
  .menu-close-button .text-label,
  .modal-print-button .text-label,
  .modal-close-button .text-label {
    color: #fff;
    font-size: 0.9rem;
    line-height: 1rem; }

.menu-close-button .text-label {
  color: #fff; }

.modal-print-button {
  margin-right: 30px; }
  .modal-print-button i {
    color: #f8e379; }

.menu-close-button i,
.modal-close-button i {
  color: #f8e379;
  font-size: 33px;
  line-height: 28px;
  margin-bottom: 0px;
  display: block;
  transition: color 0.2s ease-in-out, transform 0.2s ease-in-out; }

@media (min-width: 768px) {
  .menu-close-button:hover i,
  .modal-close-button:hover i {
    color: #fff; }
  .menu-close-button:hover .close-bar:before,
  .modal-close-button:hover .close-bar:before {
    width: 100%; } }

.modal-body {
  opacity: 0;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 100;
  top: 30px;
  transition-delay: 0.2s;
  max-width: 640px;
  margin: 0 auto; }

.menu-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; }
  .menu-body .greeting-nav-wrapper {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    border-radius: 20px 20px 0 0;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: calc(30px + env(safe-area-inset-bottom)); }
    .menu-body .greeting-nav-wrapper .greeting {
      margin-bottom: 30px;
      text-align: center; }
    .menu-body .greeting-nav-wrapper nav {
      margin-top: auto; }
  .menu-body ul.nav {
    display: flex;
    flex-direction: column;
    font-size: 17px;
    line-height: 24px; }
    .menu-body ul.nav + ul.nav {
      margin-top: 20px;
      padding-top: 20px; }
    .menu-body ul.nav li {
      margin-top: 8px;
      display: block;
      text-align: center; }
      .menu-body ul.nav li a {
        padding: 8px 0;
        display: block;
        color: #0b2733; }

.noselect, .menu-close-button,
.modal-print-button,
.modal-close-button, .share-form-item, .file-attachment-item {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.nowrap {
  white-space: nowrap; }

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.w-66 {
  width: 66% !important; }

@media (min-width: 768px) {
  .w-md-66 {
    width: 66% !important; } }

.push-button > a, .menu-close-button > a, .modal-back-button > a, .modal-print-button > a, .modal-close-button > a, .threads-container .header ul.nav > li > a {
  display: inline-block; }

.push-button > a, .menu-close-button > a, .modal-back-button > a, .modal-print-button > a, .modal-close-button > a, .threads-container .header ul.nav > li > a,
.push-button > .inner,
.menu-close-button > .inner,
.modal-back-button > .inner,
.modal-print-button > .inner,
.modal-close-button > .inner,
.threads-container .header ul.nav > li > .inner {
  transition: transform .2s ease-out; }

.push-button:hover:active > a, .menu-close-button:hover:active > a, .modal-back-button:hover:active > a, .modal-print-button:hover:active > a, .modal-close-button:hover:active > a, .threads-container .header ul.nav > li:hover:active > a,
.push-button:hover:active > .inner,
.menu-close-button:hover:active > .inner,
.modal-back-button:hover:active > .inner,
.modal-print-button:hover:active > .inner,
.modal-close-button:hover:active > .inner,
.threads-container .header ul.nav > li:hover:active > .inner {
  transform: translateY(2px);
  transition: transform .1s ease-out; }

.push-item, .share-form-item > .inner, .draft .editor-actions .btn, .attachment-uploader .selected-file .file-actions .file-action, .landing-view .section-how-it-works .play-button, .custom-checkbox .custom-control-label {
  transition: opacity .1s ease-out, transform .1s ease-out;
  transform-origin: bottom center;
  cursor: pointer; }
  .push-item:hover:active, .share-form-item > .inner:hover:active, .draft .editor-actions .btn:hover:active, .attachment-uploader .selected-file .file-actions .file-action:hover:active, .landing-view .section-how-it-works .play-button:hover:active, .custom-checkbox .custom-control-label:hover:active {
    transform: translateY(2px); }

.clipped-text {
  background: linear-gradient(to bottom, white 10%, rgba(255, 255, 255, 0.4) 40%, rgba(255, 255, 255, 0) 90%);
  background-size: cover;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.hide-scrollbar {
  /* optional: show position indicator in red */ }
  .hide-scrollbar::-webkit-scrollbar {
    width: 0;
    /* remove scrollbar space */
    background: transparent;
    /* optional: just make scrollbar invisible */ }
  .hide-scrollbar::-webkit-scrollbar-thumb {
    background: transparent; }

.custom-scrollbar {
  /* optional: show position indicator in red */ }
  .custom-scrollbar::-webkit-scrollbar {
    width: 3px;
    background: rgba(255, 255, 255, 0.2); }
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #f8e379; }

.p-safe {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right); }

.py-safe {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom); }

.px-safe {
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right); }

.pt-safe, .page-view.notifications-view > .inner {
  padding-top: env(safe-area-inset-top); }

.pb-safe, .page-view.notifications-view > .inner {
  padding-bottom: env(safe-area-inset-bottom); }

.pr-safe {
  padding-right: env(safe-area-inset-right); }

.pl-safe {
  padding-left: env(safe-area-inset-left); }

/* safe margin */
.m-safe {
  margin-top: env(safe-area-inset-top);
  margin-bottom: env(safe-area-inset-bottom);
  margin-left: env(safe-area-inset-left);
  margin-right: env(safe-area-inset-right); }

.my-safe {
  margin-top: env(safe-area-inset-top);
  margin-bottom: env(safe-area-inset-bottom); }

.mx-safe {
  margin-left: env(safe-area-inset-left);
  margin-right: env(safe-area-inset-right); }

.mt-safe {
  margin-top: env(safe-area-inset-top); }

.mb-safe {
  margin-bottom: env(safe-area-inset-bottom); }

.mr-safe {
  margin-right: env(safe-area-inset-right); }

.ml-safe {
  margin-left: env(safe-area-inset-left); }

#app-alerts {
  position: fixed;
  top: 30px;
  left: 100px;
  right: 100px;
  text-align: center;
  z-index: 10000; }
  #app-alerts .alert-wrapper {
    text-align: center; }
  #app-alerts .alert {
    cursor: pointer;
    display: inline-block;
    float: none; }

.bounce, .landing-view .cta-more .cta-icon {
  position: relative;
  bottom: 0;
  -webkit-animation: bounce 1s infinite; }

@keyframes loadingBar {
  0% {
    left: -20%; }
  40% {
    width: 25%; }
  100% {
    left: 108%;
    width: 5%; } }

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0; }
  100% {
    background-position: 400px 0; } }

.bounce, .landing-view .cta-more .cta-icon {
  position: relative;
  animation: bounce 2s infinite;
  transform-origin: center; }

@keyframes bounce {
  0%, 10%, 100% {
    transform: translateY(0);
    opacity: .7; }
  50% {
    transform: translateY(-5px);
    opacity: .4; } }

.animated-background, .thread.fetching .summary .heading, .thread.fetching .summary .question-body {
  position: relative;
  overflow: hidden; }
  .animated-background:after, .thread.fetching .summary .heading:after, .thread.fetching .summary .question-body:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: ease-out;
    background: #f6f7f8;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 8%, rgba(255, 255, 255, 0.1) 18%, rgba(255, 255, 255, 0) 33%);
    background-size: 800px 104px; }

.profile-photo-form .photo-wrapper {
  position: relative;
  width: 100px;
  overflow: hidden;
  margin: 30px auto 20px;
  cursor: pointer; }

.profile-photo-form .avatar {
  border: 4px solid #f8e379;
  padding: 3px;
  border-radius: 50%;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  transition: border .2s ease-out; }
  .profile-photo-form .avatar .photo {
    border-radius: 50%;
    background-color: #1f3d49;
    height: 86px;
    width: 86px; }
  .profile-photo-form .avatar img {
    border-radius: 50%; }

.profile-photo-form.with-error .avatar {
  border-color: #ff0000;
  color: #fff; }

.profile-photo-form .upload-icon {
  position: absolute;
  bottom: 1px;
  right: 1px;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  background: #f8e379;
  color: #1e3740;
  border: 4px solid #1e3740; }
  .profile-photo-form .upload-icon i {
    transform: rotate(-90deg);
    font-size: 14px;
    position: absolute;
    top: 2px;
    left: 0;
    right: 0;
    text-align: center; }

.manage-users .subtitle {
  margin-bottom: 30px; }

.manage-users .upgrade-cta {
  margin: 30px 0;
  text-align: center;
  font-size: 1rem; }

.manage-users .account-status {
  display: inline-block;
  width: 7px;
  height: 7px;
  background: #ee6600;
  margin-right: 10px;
  border-radius: 50%; }
  .manage-users .account-status.active {
    background: #a3f879; }

.manage-users .account-name {
  font-weight: bold;
  margin-right: 10px; }

.manage-users [class*="answerbar-"] {
  font-size: 1.2rem; }

.manage-users .invite-form {
  padding: 10px 0;
  margin-bottom: 30px; }
  .manage-users .invite-form .form-group {
    padding-right: 20px; }

.manage-users .btn.hidden {
  opacity: .3;
  pointer-events: none; }

.manage-users .team-list {
  margin-top: 10px; }

.manage-users .pending {
  margin-left: 8px;
  font-size: .8rem;
  line-height: 12px;
  opacity: .7; }

.share-form {
  margin: 30px 0; }

.share-form-item {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 8px 0;
  cursor: pointer;
  width: 100% !important; }
  .share-form-item:nth-child(1) {
    border-top: 0 none; }
  @media (min-width: 768px) {
    .share-form-item {
      max-width: 50%; }
      .share-form-item:nth-child(2) {
        border-top: 0 none; } }
  .share-form-item .photo {
    border: 2px solid #f8e379;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    padding: 1px; }
  .share-form-item .name {
    font-size: 1rem; }
  .share-form-item label.custom-control-label {
    position: relative;
    pointer-events: none; }
  .share-form-item.selected .photo {
    border-color: #fff; }

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: transparent; }

.plans {
  margin-bottom: 40px; }

.plan {
  padding: 10px;
  flex-basis: 100%;
  flex-grow: 0;
  padding: 0;
  margin-bottom: 20px; }
  .plan > .inner {
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 10px; }
  .plan h2.title {
    font-size: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .plan p.price {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 0;
    font-size: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .plan p.description {
    font-size: 16px;
    padding: 8px 0;
    margin-bottom: 0; }
  .plan ul {
    padding: 0;
    text-align: left;
    list-style: inside;
    margin: 0;
    line-height: 24px; }
    .plan ul li {
      font-size: 16px;
      line-height: 26px;
      padding: 0;
      margin-bottom: 0;
      margin: 0;
      list-style: none; }
  .plan .actions {
    font-size: 1rem;
    color: #fff;
    text-align: center; }
  .plan:hover > .inner, .plan.selected > .inner {
    border-color: #f8e379; }
  .plan.current-plan > .inner {
    border-color: #f8e379; }

.subscribe-form .StripeElement {
  padding: 6px 0;
  border-bottom: 2px solid #f8e379; }

.subscribe-form .form-info {
  font-size: 16px;
  color: #fff;
  opacity: .8;
  text-align: center;
  margin: 20px 0; }

.threads-container {
  margin-top: 105px;
  position: relative;
  z-index: 2; }
  @media (max-width: 767.8px) {
    .threads-container {
      margin-top: calc(90px); } }
  .threads-container > .inner {
    padding-top: 10px;
    padding-bottom: 100px;
    position: relative; }
  .threads-container .header {
    line-height: 60px; }
    @media (min-width: 992px) {
      .threads-container .header.row > .col-md:nth-child(1) {
        flex-basis: 41%; }
      .threads-container .header.row > .col-md:nth-child(2) {
        flex-basis: 18%; }
      .threads-container .header.row > .col-md:nth-child(3) {
        flex-basis: 41%; } }
    .threads-container .header ul.nav {
      font-size: 17px;
      white-space: nowrap;
      flex-wrap: nowrap; }
      .threads-container .header ul.nav i {
        position: relative;
        top: 1px;
        color: #ee6600; }
      .threads-container .header ul.nav.nav-secondary {
        font-size: 1rem; }
        .threads-container .header ul.nav.nav-secondary a {
          line-height: normal; }
        .threads-container .header ul.nav.nav-secondary i {
          color: #f8e379; }
    .threads-container .header .search-wrapper {
      margin-top: 10px; }
      .threads-container .header .search-wrapper .form-group {
        margin-bottom: 0; }
    .threads-container .header ul.nav-categories li {
      padding: 0;
      position: relative; }
      .threads-container .header ul.nav-categories li > a {
        line-height: 60px;
        display: block;
        padding: 0 8px 6px;
        opacity: 0.7; }
        .threads-container .header ul.nav-categories li > a:after {
          pointer-events: none;
          content: ' ';
          display: block;
          position: absolute;
          left: 50%;
          bottom: -1px;
          margin-left: -18px;
          width: 0;
          height: 0;
          border-left: 18px solid transparent;
          border-right: 18px solid transparent;
          border-bottom: 18px solid white;
          opacity: 0; }
        .threads-container .header ul.nav-categories li > a.active {
          opacity: 1; }
          .threads-container .header ul.nav-categories li > a.active:after {
            opacity: 1; }
      .threads-container .header ul.nav-categories li.notifications {
        margin-right: 10px; }
        .threads-container .header ul.nav-categories li.notifications > a {
          width: 34px;
          line-height: 28px;
          padding: 0 0 2px;
          border: 2px solid #ee6600;
          text-align: center;
          margin-top: 13px;
          border-radius: 50%;
          font-size: 1rem; }
          .threads-container .header ul.nav-categories li.notifications > a:after {
            display: none; }
    .threads-container .header .nav-label {
      display: inline-block; }
    .threads-container .header .nav-label-count {
      display: inline-block;
      font-style: italic; }
    @media (max-width: 767.8px) {
      .threads-container .header {
        display: block !important; }
        .threads-container .header.row {
          margin: 0;
          display: flex !important;
          flex-direction: column; }
          .threads-container .header.row > div[class*='col'] {
            padding: 0;
            min-height: 0; }
          .threads-container .header.row div[class*='col']:nth-child(1) {
            order: 2; }
          .threads-container .header.row div[class*='col']:nth-child(2) {
            order: 1; }
          .threads-container .header.row div[class*='col']:nth-child(3) {
            order: 3; }
        .threads-container .header .nav-scroll-container {
          overflow: hidden; }
        .threads-container .header ul.nav {
          overflow: hidden;
          overflow-x: scroll;
          white-space: nowrap;
          display: block; }
          .threads-container .header ul.nav li {
            text-align: center;
            display: inline-block; }
        .threads-container .header .nav-main {
          float: right; }
        .threads-container .header .nav-secondary,
        .threads-container .header .search-wrapper {
          background: #fff;
          color: #999;
          display: block;
          width: 100%;
          text-align: center;
          border-radius: 1px;
          margin-top: 0;
          margin-bottom: 8px;
          float: none !important;
          padding: 6px;
          line-height: 44px;
          font-size: 0.9rem; }
          .threads-container .header .nav-secondary .form-group,
          .threads-container .header .search-wrapper .form-group {
            margin-bottom: 6px; }
            .threads-container .header .nav-secondary .form-group .form-control,
            .threads-container .header .search-wrapper .form-group .form-control {
              color: #999;
              line-height: 24px;
              width: 140px;
              display: block;
              padding: 7px 0 1px;
              font-size: 1rem;
              margin: 0 auto;
              transition: all 0.3s ease-out; }
              .threads-container .header .nav-secondary .form-group .form-control::placeholder,
              .threads-container .header .search-wrapper .form-group .form-control::placeholder {
                color: #999; }
              .threads-container .header .nav-secondary .form-group .form-control:focus,
              .threads-container .header .search-wrapper .form-group .form-control:focus {
                border-bottom-color: #f8e379;
                width: 100%; }
          .threads-container .header .nav-secondary input,
          .threads-container .header .search-wrapper input {
            text-align: center; }
          .threads-container .header .nav-secondary a,
          .threads-container .header .search-wrapper a {
            color: #999; } }
  .threads-container .content-wrapper {
    min-height: 100px;
    font-family: "Open Sans", sans-serif; }
  .threads-container .avatar {
    float: left;
    margin-right: 16px;
    width: 48px;
    height: 48px; }
    .threads-container .avatar .photo {
      border-radius: 50%;
      height: 48px;
      width: 48px;
      padding: 1px;
      border: 2px solid #505050;
      color: #505050; }
      .threads-container .avatar .photo img {
        border-radius: 50%; }
      @media (max-width: 767.8px) {
        .threads-container .avatar .photo {
          padding: 0;
          border: 0 none; } }
    @media (max-width: 767.8px) {
      .threads-container .avatar {
        margin-right: 12px;
        margin-bottom: 16px; } }

.thread-background {
  overflow: hidden;
  position: absolute;
  top: 105px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  z-index: 0; }
  @media (max-width: 767.8px) {
    .thread-background {
      top: calc(90px + env(safe-area-inset-top)); } }
  .thread-background:before {
    transition: all 0.3s ease-out;
    z-index: -1;
    content: ' ';
    display: block;
    position: absolute;
    top: -30px;
    bottom: -30px;
    left: -30px;
    right: -30px;
    background: url("../images/bg-blurred-dark.jpg") no-repeat 50% 0px #08202c;
    background-size: 100%;
    transform: scale(1.5);
    transform-origin: top center; }
  .thread-background:after {
    z-index: 1;
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 200px;
    display: none;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0b2733+0,0b2733+100&0+0,1+100 */
    background: -moz-linear-gradient(top, rgba(11, 39, 51, 0) 0%, #0b2733 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(11, 39, 51, 0) 0%, #0b2733 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(11, 39, 51, 0) 0%, #0b2733 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000b2733', endColorstr='#0b2733',GradientType=0 );
    /* IE6-9 */ }

.thread {
  font-size: 13px;
  position: relative;
  transition: height 0.3s ease-in-out; }
  .thread p {
    font-size: 13px; }
  .thread .question-body p,
  .thread .question-body ol,
  .thread .question-body ul,
  .thread .body p,
  .thread .body ol,
  .thread .body ul {
    font-size: 13px;
    line-height: 20px; }
  @media (max-width: 767.8px) {
    .thread .question-body,
    .thread .body {
      clear: left; } }
  .thread:nth-child(even) > .inner > .thread-wrapper > .inner > .question {
    background-color: #f8f8f8; }
  .thread > .inner > .thread-wrapper {
    border-radius: 1px;
    color: #505050; }
    .thread > .inner > .thread-wrapper > .inner > .question {
      background-color: #fff; }
  .thread .thread-wrapper {
    position: relative;
    z-index: 2;
    left: 0;
    transition: left 0.5s ease-in-out, opacity 0.5s ease-in-out, transform 0.5s ease-in-out, height 0.5s ease-in-out;
    transform-origin: middle left; }
    .thread .thread-wrapper.is-trashed {
      opacity: 0;
      transform: translateX(-100%); }
    .thread .thread-wrapper .nav-shadow:after {
      left: 0;
      width: 100%; }
  .thread .restore-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    text-align: center;
    padding: 20px;
    font-family: "Playfair Display", serif; }
    .thread .restore-wrapper [class^='answerbar'] {
      font-size: 44px;
      margin-bottom: 6px; }
    .thread .restore-wrapper span.text,
    .thread .restore-wrapper span.link {
      font-size: 14px;
      line-height: 18px;
      font-weight: 600; }
    @media (max-width: 767.8px) {
      .thread .restore-wrapper {
        padding: 16px 16px; } }
  .thread a,
  .thread a:link,
  .thread a:visited {
    color: #505050; }
  .thread a:hover,
  .thread a:active {
    color: #505050; }
  .thread .nav {
    float: right;
    margin-top: 30px;
    margin-right: 30px;
    position: relative;
    z-index: 100; }
    .thread .nav > li {
      display: block;
      padding: 0 4px;
      min-width: 30px;
      cursor: pointer;
      text-align: center;
      transition: color 0.2s ease; }
      .thread .nav > li > span {
        display: block;
        transform-origin: center;
        transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 2.275), opacity 0.2s ease; }
      .thread .nav > li:hover {
        color: #f8e379; }
      .thread .nav > li:active > span {
        transform: scale(0.9); }
    .thread .nav i {
      display: block;
      font-size: 22px; }
      .thread .nav i + i {
        position: absolute;
        top: 0; }
    @media (max-width: 767.8px) {
      .thread .nav {
        padding: 0;
        float: none;
        margin: 0;
        display: flex; }
        .thread .nav > li {
          flex: 1 1 auto !important;
          padding: 16px 16px;
          transform-origin: top right; }
          .thread .nav > li:first-child > span {
            float: left; }
          .thread .nav > li:last-child > span {
            float: right; } }
  .thread .summary-wrapper {
    line-height: 24px;
    padding-right: 60px; }
  .thread .summary {
    display: inline; }
    .thread .summary p,
    .thread .summary ol,
    .thread .summary ul,
    .thread .summary li,
    .thread .summary a,
    .thread .summary div,
    .thread .summary span {
      margin: 0;
      padding: 0;
      display: inline; }
    .thread .summary b,
    .thread .summary strong {
      font-weight: normal; }
    .thread .summary i,
    .thread .summary em {
      font-style: normal; }
    .thread .summary u {
      text-decoration: none; }
  .thread .heading {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.1pt;
    width: 100%;
    padding-top: 4px;
    margin-bottom: 4px; }
  .thread .question-body {
    max-width: 70%;
    line-height: 24px;
    margin-left: 64px; }
    @media (max-width: 767.8px) {
      .thread .question-body {
        max-width: initial;
        overflow: hidden; } }
    .thread .question-body p:last-child {
      margin-bottom: 0; }
  .thread.fetching .summary .heading {
    width: 50%;
    height: 15px;
    background: #505050;
    border-radius: 2px; }
  .thread.fetching .summary .question-body {
    height: 15px;
    overflow: hidden;
    background: #ccc;
    border-radius: 2px;
    text-indent: -9999px;
    display: block;
    overflow: hidden; }
  .thread .nav-shadow {
    display: none; }
  .thread.collapsed {
    cursor: pointer;
    overflow: hidden; }
    .thread.collapsed .thread-details,
    .thread.collapsed .thread-actions {
      height: 0; }
      .thread.collapsed .thread-details > .inner,
      .thread.collapsed .thread-actions > .inner {
        opacity: 0;
        transform: translateY(-60px); }
    .thread.collapsed ul.nav li[data-action='collapse'] {
      display: none; }
    .thread.collapsed .question-avatar {
      width: 0;
      float: left;
      opacity: 0; }
    .thread.collapsed .question-body,
    .thread.collapsed .attachments-wrapper,
    .thread.collapsed .location {
      display: none; }
    .thread.collapsed ul.nav .expanded-link {
      visibility: hidden;
      pointer-events: none;
      display: none; }
    .thread.collapsed .not-mine {
      opacity: 0; }
    @media (max-width: 767.8px) {
      .thread.collapsed .question > .inner {
        padding: 12px 16px; }
      .thread.collapsed ul.nav {
        position: absolute;
        right: 0;
        background: #f8f8f8; }
        .thread.collapsed ul.nav:before {
          content: ' ';
          display: block;
          position: absolute;
          top: 0;
          left: -10px;
          bottom: 0;
          width: 10px;
          background: #f8f8f8;
          background: linear-gradient(90deg, rgba(248, 248, 248, 0) 0%, #f8f8f8 100%); }
      .thread.collapsed .main-heading {
        padding-top: 0;
        margin-bottom: 12px;
        padding-right: 40px; }
        .thread.collapsed .main-heading .heading-shadow {
          margin-top: 10px;
          width: auto;
          display: block;
          margin-left: -16px;
          margin-right: -16px; }
          .thread.collapsed .main-heading .heading-shadow:after {
            left: 0;
            width: 100%; } }
  .thread.expanded ul.nav li[data-action='expand'] {
    display: none; }
  @media (max-width: 767.8px) {
    .thread.expanded .nav-shadow {
      display: block; } }
  .thread.expanded .unread-flag,
  .thread.expanded .attachment-flag,
  .thread.expanded .draft-flag,
  .thread.expanded .summary-wrapper {
    display: none; }
  @media (max-width: 767.8px) {
    .thread.expanded > .inner > .thread-wrapper {
      background-color: #fff; } }
  .thread.expanded .main-heading {
    padding-right: 0; }
  .thread.removed {
    opacity: 0;
    pointer-events: none;
    height: 0 !important;
    margin-bottom: -8px; }
  .thread ul.nav > li.disabled {
    display: none;
    opacity: 0.2;
    color: #000; }
    .thread ul.nav > li.disabled:hover {
      color: #000; }
  .thread + .thread {
    margin-top: 8px; }
  .thread .question {
    position: relative;
    z-index: 10; }
    .thread .question > .inner {
      padding: 30px; }
      .thread .question > .inner:after {
        content: ' ';
        display: block;
        clear: both; }
    @media (min-width: 768px) {
      .thread .question .attachments-wrapper {
        margin-top: 20px;
        margin-left: 64px; } }
  .thread .heading-shadow {
    display: none; }
  .thread .not-mine {
    position: absolute;
    top: 32px;
    pointer-events: none;
    right: 100%;
    font-size: 48px;
    z-index: 200; }
    .thread .not-mine:before {
      left: 100%;
      top: 50%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(8, 32, 44, 0);
      border-left-color: #0b2733;
      border-width: 19px;
      margin-top: -19px; }
    .thread .not-mine > span {
      position: relative;
      color: #fff;
      right: -22px; }
    @media (max-width: 767.8px) {
      .thread .not-mine {
        top: 66px;
        transform-origin: center right;
        transform: scale(0.4); }
        .thread .not-mine:before {
          border-width: 23px;
          margin-top: -23px; }
        .thread .not-mine > span {
          right: -23px; } }
  .thread .shared-with {
    text-align: center; }
    @media (min-width: 768px) {
      .thread .shared-with {
        float: left; } }
    .thread .shared-with .photos:after {
      content: ' ';
      display: block;
      clear: both; }
    .thread .shared-with .avatar {
      float: left;
      height: 42px;
      width: 42px;
      background: #1e3740;
      border: 2px solid #f7f7f7;
      border-radius: 50%;
      margin-right: 0; }
      .thread .shared-with .avatar + .avatar {
        margin-left: -10px; }
      .thread .shared-with .avatar .photo {
        width: 100%;
        height: 100%;
        border: 0 none;
        padding: 0; }
    .thread .shared-with a {
      font-family: "Playfair Display", serif; }
  .thread .draft-wrapper {
    position: relative;
    z-index: 3; }
  .thread .answer .attachments-wrapper {
    margin-top: 20px;
    margin-left: 0px; }
    @media (min-width: 768px) {
      .thread .answer .attachments-wrapper {
        margin-left: 64px; } }
  .thread .answer .file-attachment-item > .inner {
    background: #fff; }
  @media (max-width: 767.8px) {
    .thread .answer {
      width: 100% !important; }
      .thread .answer > .inner {
        width: 100% !important; } }
  .thread .answer .body {
    margin-left: 64px; }
    @media (max-width: 767.8px) {
      .thread .answer .body {
        margin-left: 0; } }
    .thread .answer .body span p:last-child {
      margin-bottom: 0; }
  .thread .answer-cta {
    overflow: hidden;
    font-family: "Playfair Display", serif;
    text-align: right;
    font-weight: 600; }
    @media (max-width: 767.8px) {
      .thread .answer-cta {
        text-align: center; } }
    .thread .answer-cta > .inner:after {
      content: ' ';
      display: block;
      clear: both; }
    .thread .answer-cta .radial-shadow:after {
      top: -2px;
      height: 3px;
      opacity: 0.3;
      left: 8%;
      width: 90%;
      -webkit-filter: blur(4px);
      -moz-filter: blur(4px);
      -o-filter: blur(4px);
      -ms-filter: blur(4px);
      filter: blur(4px); }
    .thread .answer-cta .cta {
      cursor: pointer;
      padding-bottom: 4px;
      position: relative;
      text-shadow: 0 14px 10px rgba(0, 0, 0, 0.1);
      float: right; }
      .thread .answer-cta .cta:before {
        pointer-events: none;
        border-radius: 5px;
        content: ' ';
        display: block;
        height: 4px;
        position: absolute;
        left: -50%;
        width: 200%;
        bottom: 0px;
        background-color: #ee6600;
        transform: scale(0.5);
        transform-origin: bottom center;
        transition: left .2s ease-out, width .2s ease-out; }
      .thread .answer-cta .cta:after {
        pointer-events: none;
        border-radius: 5px;
        content: ' ';
        display: block;
        height: 4px;
        position: absolute;
        left: 50%;
        width: 00%;
        bottom: 0px;
        background-color: transparent;
        transform: scale(0.5);
        transform-origin: bottom center;
        transition: left .2s ease-out, width .2s ease-out;
        transition-delay: 0s; }
      @media (min-width: 768px) {
        .thread .answer-cta .cta:hover, .thread .answer-cta .cta:active, .thread .answer-cta .cta:focus {
          text-shadow: 0 6px 10px transparent; }
          .thread .answer-cta .cta:hover:after, .thread .answer-cta .cta:active:after, .thread .answer-cta .cta:focus:after {
            left: -50%;
            transition-delay: .03s;
            width: 200%; } }
      @media (max-width: 767.8px) {
        .thread .answer-cta .cta {
          display: inline-block;
          float: none; } }
    .thread .answer-cta i {
      color: #ee6600;
      font-size: 14px;
      position: relative;
      top: 2px;
      margin-left: 2px; }
  @media (max-width: 767.8px) {
    .thread .awaiting-answer > .inner {
      width: 100% !important; } }
  .thread .awaiting-answer .avatar {
    margin-left: -24px; }
    @media (max-width: 767.8px) {
      .thread .awaiting-answer .avatar {
        margin-left: 0; } }
  .thread .awaiting-answer .message {
    font-weight: 600;
    line-height: 22px; }
  .thread .awaiting-answer .notify-cta {
    margin-bottom: 2px;
    margin-left: 40px; }
    @media (max-width: 767.8px) {
      .thread .awaiting-answer .notify-cta {
        margin-left: 0; }
        .thread .awaiting-answer .notify-cta a {
          display: inline-block; }
          .thread .awaiting-answer .notify-cta a:before {
            content: ' ';
            display: block;
            clear: both; } }
  .thread .awaiting-answer a {
    font-weight: 600;
    padding-bottom: 4px;
    position: relative;
    text-shadow: 0 14px 10px rgba(0, 0, 0, 0.1); }
    .thread .awaiting-answer a:before {
      pointer-events: none;
      border-radius: 5px;
      content: ' ';
      display: block;
      height: 3px;
      position: absolute;
      left: -50%;
      width: 200%;
      bottom: 0px;
      background-color: #f8e379;
      transform: scale(0.5);
      transform-origin: bottom center;
      transition: left .2s ease-out, width .2s ease-out; }
    .thread .awaiting-answer a:after {
      pointer-events: none;
      border-radius: 5px;
      content: ' ';
      display: block;
      height: 3px;
      position: absolute;
      left: 50%;
      width: 00%;
      bottom: 0px;
      background-color: transparent;
      transform: scale(0.5);
      transform-origin: bottom center;
      transition: left .2s ease-out, width .2s ease-out;
      transition-delay: 0s; }
    @media (min-width: 768px) {
      .thread .awaiting-answer a:hover, .thread .awaiting-answer a:active, .thread .awaiting-answer a:focus {
        text-shadow: 0 6px 10px transparent; }
        .thread .awaiting-answer a:hover:after, .thread .awaiting-answer a:active:after, .thread .awaiting-answer a:focus:after {
          left: -50%;
          transition-delay: .03s;
          width: 200%; } }
  @media (max-width: 767.8px) {
    .thread {
      font-size: 12px;
      line-height: 16px; }
      .thread .main-heading {
        margin-bottom: 20px; }
        .thread .main-heading .separator {
          display: none; }
        .thread .main-heading .date {
          display: inline-block; }
        .thread .main-heading .radial-shadow {
          display: none; }
      .thread .question > .inner {
        padding: 16px 16px; }
      .thread .question-body {
        margin-left: 0;
        clear: left; } }

.thread .radial-shadow {
  position: relative;
  z-index: 100; }
  .thread .radial-shadow:after {
    top: -2px;
    height: 3px;
    margin-top: 0px;
    left: 5%;
    width: 90%;
    opacity: 0.5;
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px); }

.question-confirm {
  text-align: center;
  margin-top: 120px; }
  .question-confirm .initial-question {
    font-size: 1rem; }
  .question-confirm .form-actions {
    margin-top: 20px;
    padding-top: 20px;
    overflow: hidden;
    min-height: 80px; }
    .question-confirm .form-actions .radial-shadow:after {
      top: -15px;
      height: 30px;
      left: 30%;
      width: 40%;
      -webkit-filter: blur(24px);
      -moz-filter: blur(24px);
      -o-filter: blur(24px);
      -ms-filter: blur(24px);
      filter: blur(24px); }
    .question-confirm .form-actions > .inner {
      position: relative;
      z-index: 2; }
  .question-confirm .edit-cta {
    margin-top: 120px; }
    .question-confirm .edit-cta > a {
      cursor: pointer;
      font-size: 1rem;
      padding-bottom: 4px;
      position: relative;
      text-shadow: 0 14px 10px rgba(0, 0, 0, 0.1); }
      .question-confirm .edit-cta > a:before {
        pointer-events: none;
        border-radius: 5px;
        content: ' ';
        display: block;
        height: 3px;
        position: absolute;
        left: -50%;
        width: 200%;
        bottom: 0px;
        background-color: #f8e379;
        transform: scale(0.5);
        transform-origin: bottom center;
        transition: left .2s ease-out, width .2s ease-out; }
      .question-confirm .edit-cta > a:after {
        pointer-events: none;
        border-radius: 5px;
        content: ' ';
        display: block;
        height: 3px;
        position: absolute;
        left: 50%;
        width: 00%;
        bottom: 0px;
        background-color: transparent;
        transform: scale(0.5);
        transform-origin: bottom center;
        transition: left .2s ease-out, width .2s ease-out;
        transition-delay: 0s; }
      @media (min-width: 768px) {
        .question-confirm .edit-cta > a:hover, .question-confirm .edit-cta > a:active, .question-confirm .edit-cta > a:focus {
          text-shadow: 0 6px 10px transparent; }
          .question-confirm .edit-cta > a:hover:after, .question-confirm .edit-cta > a:active:after, .question-confirm .edit-cta > a:focus:after {
            left: -50%;
            transition-delay: .03s;
            width: 200%; } }

.thread-details {
  position: relative;
  z-index: 2; }
  .thread-details > .inner {
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    background: #f7f7f7; }
    @media (max-width: 767.8px) {
      .thread-details > .inner {
        padding: 16px 16px; } }
    .thread-details > .inner .shared-answer-wrapper {
      padding: 30px; }
      @media (min-width: 768px) {
        .thread-details > .inner .shared-answer-wrapper.is-shared {
          padding-right: 106px; } }
    .thread-details > .inner .shared-with-wrapper {
      min-width: 106px; }
    @media (max-width: 767.8px) {
      .thread-details > .inner .shared-answer-wrapper {
        padding: 0 0 0;
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
      .thread-details > .inner .shared-with-wrapper {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px; } }

.thread-actions {
  position: relative;
  z-index: 1; }
  @media (max-width: 767.8px) {
    .thread-actions > div {
      width: 100% !important; } }
  .thread-actions > .inner {
    padding: 30px;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    background: #f7f7f7;
    padding-top: 0; }
    @media (max-width: 767.8px) {
      .thread-actions > .inner {
        padding: 24px 16px; } }

@media (max-width: 767.8px) {
  .child-questions {
    margin: 0 -16px;
    padding-top: 16px; } }

.child-questions .question {
  background: #fff; }

.child-questions .answer {
  padding: 30px; }
  @media (max-width: 767.8px) {
    .child-questions .answer {
      padding: 16px; } }

.child-questions .awaiting-answer {
  padding: 30px 30px; }

.draft > .inner {
  padding: 30px;
  background-color: #fff;
  border-radius: 1px;
  color: #505050; }
  @media (max-width: 767.8px) {
    .draft > .inner {
      padding: 16px; } }

.draft .toolbar {
  padding-bottom: 30px;
  font-family: "Playfair Display", serif; }
  @media (min-width: 768px) {
    .draft .toolbar .auto-save-message,
    .draft .toolbar .draft-actions {
      width: 160px; }
    .draft .toolbar .draft-actions {
      text-align: right; } }
  @media (max-width: 767.8px) {
    .draft .toolbar .auto-save-message {
      order: 1; }
    .draft .toolbar .editor-actions {
      order: 3; }
    .draft .toolbar .draft-actions {
      order: 2; }
    .draft .toolbar .editor-actions {
      margin-top: 16px;
      width: 100% !important; }
      .draft .toolbar .editor-actions .ql-toolbar {
        width: 100%; }
      .draft .toolbar .editor-actions .btn {
        width: 11.61625%; }
        .draft .toolbar .editor-actions .btn + .btn {
          margin-left: 1%; } }

.draft .editor-wrapper {
  position: relative;
  overflow: hidden; }

.draft .attachments-wrapper {
  margin-bottom: 24px; }

.draft .form-actions {
  position: relative;
  text-align: center;
  padding-top: 30px;
  font-family: "Playfair Display", serif; }
  .draft .form-actions .radial-shadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0; }
  .draft .form-actions .btn-primary {
    color: #505050; }

.draft .location-select {
  text-align: left;
  white-space: nowrap; }
  @media (min-width: 768px) {
    .draft .location-select {
      position: absolute; } }
  .draft .location-select .control-label {
    color: #999;
    font-size: 0.9rem;
    display: inline-block;
    margin-right: 6px;
    display: none; }
  .draft .location-select.form-group select.form-control {
    -webkit-appearance: none;
    -webkit-border-radius: 1px;
    padding: 0 4px;
    border: 0 none;
    background-color: #fff;
    color: #000;
    border-radius: 2px;
    line-height: 32px;
    height: 32px;
    font-weight: 400;
    font-size: 0.9rem;
    display: inline-block;
    border-bottom: 2px solid #f8e379;
    width: 100%;
    min-width: 0;
    cursor: pointer; }
    .draft .location-select.form-group select.form-control.is-invalid {
      border-bottom-color: #ff0000; }
  .draft .location-select.form-group .select-wrapper {
    position: relative;
    display: block; }
    .draft .location-select.form-group .select-wrapper select {
      padding-right: 32px; }
    .draft .location-select.form-group .select-wrapper:after {
      pointer-events: none;
      content: ' ';
      display: block;
      width: 24px;
      height: 32px;
      line-height: 32px;
      font-size: 0.8rem;
      position: absolute;
      color: #999;
      top: 0;
      right: 0;
      content: '\e908';
      font-family: AnswerBar;
      text-align: center; }
  .draft .location-select .invalid-feedback {
    line-height: 20px;
    margin: 0; }

.draft .editor-actions .ql-toolbar {
  display: inline-block; }

.draft .editor-actions .btn {
  font-size: 18px;
  line-height: 20px;
  height: 32px;
  background: #f7f7f7;
  border-radius: 2px;
  padding: 4px 4px;
  min-width: 30px;
  width: 32px;
  border-bottom: 2px solid transparent;
  transform-origin: bottom center;
  overflow: hidden; }
  .draft .editor-actions .btn:hover {
    background: #f4f4f4;
    border-bottom-color: #e8e8e8; }
  .draft .editor-actions .btn + .btn {
    margin-left: 3px; }
  .draft .editor-actions .btn.ql-active {
    border-bottom-color: #f8e379; }
  .draft .editor-actions .btn:focus {
    border-bottom-color: #f8e379;
    box-shadow: none;
    outline: none; }
  .draft .editor-actions .btn i {
    font-size: 18px;
    position: relative;
    top: 1px; }

.draft .draft-actions .action,
.draft .draft-actions a {
  font-weight: 600;
  cursor: pointer;
  padding-bottom: 4px;
  position: relative;
  text-shadow: 0 14px 10px rgba(0, 0, 0, 0.1); }
  .draft .draft-actions .action:before,
  .draft .draft-actions a:before {
    pointer-events: none;
    border-radius: 5px;
    content: ' ';
    display: block;
    height: 3px;
    position: absolute;
    left: -50%;
    width: 200%;
    bottom: 0px;
    background-color: #ee6600;
    transform: scale(0.5);
    transform-origin: bottom center;
    transition: left .2s ease-out, width .2s ease-out; }
  .draft .draft-actions .action:after,
  .draft .draft-actions a:after {
    pointer-events: none;
    border-radius: 5px;
    content: ' ';
    display: block;
    height: 3px;
    position: absolute;
    left: 50%;
    width: 00%;
    bottom: 0px;
    background-color: transparent;
    transform: scale(0.5);
    transform-origin: bottom center;
    transition: left .2s ease-out, width .2s ease-out;
    transition-delay: 0s; }
  @media (min-width: 768px) {
    .draft .draft-actions .action:hover, .draft .draft-actions .action:active, .draft .draft-actions .action:focus,
    .draft .draft-actions a:hover,
    .draft .draft-actions a:active,
    .draft .draft-actions a:focus {
      text-shadow: 0 6px 10px transparent; }
      .draft .draft-actions .action:hover:after, .draft .draft-actions .action:active:after, .draft .draft-actions .action:focus:after,
      .draft .draft-actions a:hover:after,
      .draft .draft-actions a:active:after,
      .draft .draft-actions a:focus:after {
        left: -50%;
        transition-delay: .03s;
        width: 200%; } }

.draft .draft-actions i {
  position: relative;
  top: 1px; }

.draft .auto-save-message {
  color: #a3a3a3;
  transition: opacity 0.2s ease-out; }
  .draft .auto-save-message.hidden {
    opacity: 0; }

.draft .radial-shadow.top:after {
  top: -2px;
  height: 3px;
  margin-top: 0px;
  left: 5%;
  width: 90%;
  opacity: 0.5;
  -webkit-filter: blur(6px);
  -moz-filter: blur(6px);
  -o-filter: blur(6px);
  -ms-filter: blur(6px);
  filter: blur(6px); }

.draft .public-DraftEditor-content {
  min-height: 120px;
  padding-top: 20px;
  padding-bottom: 20px; }

.thread .answer-wrapper {
  transition: all 1s ease;
  position: relative;
  transform-origin: top;
  transition-duration: 0.3s;
  transition-property: all;
  transition-easing: ease-in;
  order: 2; }

.thread .draft-wrapper {
  position: relative;
  z-index: 1;
  origin-perspective: top center;
  perspective: 1800px; }

.thread .draft-wrapper .draft {
  position: relative;
  z-index: 2;
  opacity: 0;
  transform-origin: top center;
  transform: rotateX(-95deg) translateY(0px);
  transition: transform 0.5s ease-in-out, height 1s linear, opacity 0.5s linear;
  transition-delay: 0.5s; }

.thread.collapsed .accordian-bar > .inner {
  height: 0;
  opacity: 0; }

.thread.collapsed .thread-details,
.thread.collapsed .thread-actions {
  height: 0;
  pointer-events: none; }

.thread.collapsed .draft-wrapper {
  height: 0;
  overflow: hidden;
  pointer-events: none; }
  .thread.collapsed .draft-wrapper .draft {
    transition-delay: 0s; }

.thread.expanded .accordian-bar > .inner {
  opacity: 1;
  height: 20px;
  transition-delay: 0.3s; }

.thread.expanded .draft-wrapper .draft {
  transform: none;
  opacity: 1; }

.accordian-bar {
  height: 20px;
  position: relative;
  z-index: 3; }
  .accordian-bar > .inner {
    -webkit-perspective: 400px;
    -moz-perspective: 400px;
    -o-perspective: 400px;
    perspective: 400px;
    position: relative;
    transition: transform 0.2s linear, height 0.2s linear, opacity 0.2s linear;
    transition-delay: 0s;
    height: 20px; }
    .accordian-bar > .inner:before {
      content: ' ';
      display: block;
      background: #9aa1a4;
      height: 59%;
      position: absolute;
      top: 0;
      left: 0px;
      right: 0px;
      margin-bottom: 1px;
      -webkit-transform: rotateX(-30deg);
      -moz-transform: rotateX(-30deg);
      -o-transform: rotateX(-30deg);
      transform: rotateX(-30deg);
      transform-origin: top center;
      border-bottom: 1px solid #828e93;
      z-index: 2; }
    .accordian-bar > .inner:after {
      content: ' ';
      display: block;
      background: #b0b7ba;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0px;
      right: 0px;
      -webkit-transform: rotateX(30deg);
      -moz-transform: rotateX(30deg);
      -o-transform: rotateX(30deg);
      transform: rotateX(30deg);
      transform-origin: bottom center;
      z-index: 1; }

.attachment-flag {
  display: inline-block;
  margin-right: 3px; }
  .attachment-flag i {
    display: inline-block;
    font-size: 16px;
    position: relative;
    top: 3px; }

.unread-flag {
  display: inline-block;
  margin-right: 3px;
  color: #ee6600;
  font-style: italic;
  font-weight: 600; }
  .unread-flag:after {
    content: ' - ';
    display: inline-block;
    color: #545454;
    margin-left: 3px; }

.draft-flag {
  display: inline-block;
  margin-right: 3px;
  color: #ee6600;
  font-style: italic;
  font-weight: 600; }
  .draft-flag:after {
    content: ' - ';
    display: inline-block;
    color: #545454;
    margin-left: 3px; }

.attachments-wrapper {
  margin-top: 20px; }

.file-attachment-item {
  display: inline-block;
  margin-bottom: 0.5%;
  margin-right: 0.5%;
  width: 49.5%; }
  @media (max-width: 767.8px) {
    .file-attachment-item {
      line-height: 28px; } }
  .file-attachment-item > .inner {
    display: block;
    border: 2px solid #fdf7da;
    padding: 3px 6px;
    background: #fff;
    font-family: "Playfair Display", serif;
    font-size: 12px;
    cursor: pointer; }
  .file-attachment-item .filename {
    font-weight: 600;
    margin-right: 6px; }
  .file-attachment-item .filesize {
    color: #676767;
    margin-right: 6px; }
  .file-attachment-item i {
    position: relative;
    top: 6px;
    float: right;
    font-size: 16px; }
  .file-attachment-item .push-item, .file-attachment-item .share-form-item > .inner, .file-attachment-item .draft .editor-actions .btn, .draft .editor-actions .file-attachment-item .btn, .file-attachment-item .attachment-uploader .selected-file .file-actions .file-action, .attachment-uploader .selected-file .file-actions .file-attachment-item .file-action, .file-attachment-item .landing-view .section-how-it-works .play-button, .landing-view .section-how-it-works .file-attachment-item .play-button, .file-attachment-item .custom-checkbox .custom-control-label, .custom-checkbox .file-attachment-item .custom-control-label {
    display: block; }

.attachment-frame {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.8); }
  .attachment-frame iframe {
    position: absolute;
    background-color: #fff;
    text-align: center;
    top: 5vh;
    left: 2%;
    z-index: 10;
    width: 96%;
    height: 90vh; }

.attachment-uploader .upload-icon {
  display: block; }
  .attachment-uploader .upload-icon i.material-icons {
    display: block;
    transform: rotate(-90deg);
    font-size: 20px;
    margin-bottom: 0px; }
  .attachment-uploader .upload-icon .upload-bar {
    width: 22px;
    height: 3px;
    margin: 0 auto;
    border-radius: 1px;
    background: #fff;
    display: block; }

.attachment-uploader .selected-files {
  font-size: 0.9rem;
  margin: 16px 0;
  text-align: left; }

.attachment-uploader .selected-file .file-name {
  font-weight: 600;
  white-space: nowrap; }

.attachment-uploader .selected-file .file-size {
  opacity: 0.8;
  white-space: nowrap; }

.attachment-uploader .selected-file .file-actions .file-action {
  cursor: pointer; }

.attachment-uploader .selected-file i {
  font-size: 1.2rem; }

.attachment-uploader .error-message {
  position: relative;
  padding-left: 20px;
  display: inline-block;
  display: none; }
  .attachment-uploader .error-message:before {
    content: ' ';
    display: block;
    position: absolute;
    background-color: #ff0000;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    left: 0px;
    margin-top: 13px; }

.dropzone {
  border: 2px dashed rgba(255, 255, 255, 0);
  min-height: 200px;
  border-radius: 5px;
  transition: border 0.1s linear, background 0.1s linear;
  text-align: center;
  cursor: pointer; }
  .dropzone.dropzone--isActive {
    border-color: rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.02); }
  .dropzone .cta-text {
    margin: 20px 0;
    font-size: 22px;
    line-height: 30px; }
  .dropzone:focus {
    outline: none; }
  .dropzone:active {
    border-color: rgba(255, 255, 255, 0.1);
    transform: translateY(1px);
    background: rgba(255, 255, 255, 0.02); }

.progress-bar-wrapper {
  margin: 6px auto 8px;
  position: relative;
  height: 2px; }
  .progress-bar-wrapper .progress-bar {
    background: rgba(255, 255, 255, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 6px; }
  .progress-bar-wrapper .progress-bar-progress {
    background-color: #fcf3c7;
    border-radius: 6px;
    height: 100%;
    width: 0%;
    transition: width 0.2s ease, background 0.2s ease; }
  .progress-bar-wrapper.started .progress-bar-progress {
    width: 0%; }
  .progress-bar-wrapper.complete .progress-bar-progress {
    width: 100%;
    background-color: #f8e379; }
  .progress-bar-wrapper:not(.complete) .progress-bar-progress {
    max-width: 98%; }
  .progress-bar-wrapper.error .progress-bar-progress {
    background-color: #ff0000; }

.ql-editor.ql-blank::before {
  font-style: normal;
  color: #999; }

.empty-text {
  text-align: center;
  font-family: "Playfair Display", serif;
  font-size: 1rem;
  color: #999;
  padding: 40px 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  background: #fff; }
  .empty-text a,
  .empty-text a:link,
  .empty-text a:visited {
    color: #0b2733; }

.search-wrapper {
  position: relative; }
  .search-wrapper .close-wrapper {
    position: absolute;
    right: 0;
    top: 5px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out; }
  .search-wrapper.with-query .close-wrapper {
    display: block;
    opacity: 1;
    pointer-events: auto; }

.faqs-view {
  background: #17323d; }
  .faqs-view .page-header {
    background: url(../images/bg-testimonials.jpg);
    background-size: cover;
    background-position: 50% 90%;
    position: relative;
    display: flex;
    align-items: center;
    height: 400px;
    text-align: center; }
    .faqs-view .page-header h1 {
      margin-bottom: 40px; }

.faq-category {
  padding: 100px 0 80px; }
  .faq-category .faq {
    padding: 0 25px; }
    @media (min-width: 768px) {
      .faq-category .faq {
        max-width: 555px;
        margin-left: auto;
        margin-right: auto; } }
    .faq-category .faq + .faq {
      margin-top: 60px; }

.faq-category-title {
  font-size: 1.2rem;
  margin-bottom: 30px;
  opacity: .5;
  color: #fff;
  padding: 0 25px; }
  @media (min-width: 768px) {
    .faq-category-title {
      max-width: 555px;
      margin-left: auto;
      margin-right: auto; } }

.faq-category:nth-child(even) {
  background: #e2e0d9;
  color: #17323d; }
  .faq-category:nth-child(even) .faq-category-title {
    color: #1e3740; }

.faq-question {
  margin-bottom: 30px;
  font-size: 28px;
  line-height: 44px;
  text-align: left; }

.faq-answer {
  font-size: 1rem;
  line-height: 2rem;
  margin-bottom: 30px; }

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box; }
  .glide * {
    box-sizing: inherit; }
  .glide__track {
    overflow: hidden; }
  .glide__slides {
    position: relative;
    width: 100%;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform; }
    .glide__slides--dragging {
      user-select: none; }
  .glide__slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent; }
    .glide__slide a {
      user-select: none;
      -webkit-user-drag: none;
      -moz-user-select: none;
      -ms-user-select: none; }
  .glide__arrows {
    -webkit-touch-callout: none;
    user-select: none; }
  .glide__bullets {
    -webkit-touch-callout: none;
    user-select: none; }
  .glide--rtl {
    direction: rtl; }

.glide__arrow {
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  padding: 9px 12px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1; }
  .glide__arrow:focus {
    outline: none; }
  .glide__arrow:hover {
    border-color: white; }
  .glide__arrow--left {
    left: 2em; }
  .glide__arrow--right {
    right: 2em; }
  .glide__arrow--disabled {
    opacity: 0.33; }

.glide__bullets {
  position: absolute;
  z-index: 2;
  bottom: 2em;
  left: 50%;
  display: inline-flex;
  list-style: none;
  transform: translateX(-50%); }

.glide__bullet {
  background-color: rgba(255, 255, 255, 0.5);
  width: 9px;
  height: 9px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  line-height: 0;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  margin: 0 0.25em; }
  .glide__bullet:focus {
    outline: none; }
  .glide__bullet:hover, .glide__bullet:focus {
    border: 2px solid white;
    background-color: rgba(255, 255, 255, 0.5); }
  .glide__bullet--active {
    background-color: white; }

.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab; }

.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing; }

.glide__slides {
  margin-bottom: 0;
  height: 100%; }

.glide__arrow {
  border: 0 none; }
  .glide__arrow:active > span {
    transform: translateY(1px); }

.glide__arrow--left {
  left: 0; }

.glide__arrow--right {
  right: 0; }

.glide__bullet {
  margin: 0 8px;
  background-color: rgba(255, 255, 255, 0.2); }
  .glide__bullet:hover {
    border: 0 none; }
  .glide__bullet:focus {
    border: 0 none;
    background: #fff; }

.glide__bullet--active {
  background-color: #fff; }

.glide-dark .glide__arrow {
  color: #fff; }

.glide-dark .glide__bullet {
  background-color: rgba(11, 39, 51, 0.2);
  box-shadow: none; }
  .glide-dark .glide__bullet.glide__bullet--active {
    background-color: rgba(11, 39, 51, 0.8); }

.sample-draft-carousel.glide .glide__bullets {
  bottom: 0; }

.sample-draft-carousel.glide .glide__arrow {
  box-shadow: none;
  z-index: 1001;
  font-size: 22px; }
  .sample-draft-carousel.glide .glide__arrow.glide__arrow--left {
    left: -60px; }
  .sample-draft-carousel.glide .glide__arrow.glide__arrow--right {
    right: -60px; }

html,
* {
  text-rendering: optimizelegibility;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased; }

* {
  box-sizing: border-box; }

body,
html {
  height: 100%; }

html {
  background-color: #0b2733;
  font-size: 100%; }

body {
  color: #fff;
  background: #0b2733;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  line-height: 22pt; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif; }

p {
  margin-bottom: 16px;
  line-height: 2rem; }
  p.large {
    font-size: 1.1rem; }

a,
a:link,
a:visited {
  color: #fff;
  text-decoration: none; }

a:hover,
a:focus {
  color: #fff; }

a.underlined,
.link.underlined {
  padding-left: 1px;
  padding-right: 1px;
  padding-bottom: 4px;
  position: relative;
  text-shadow: 0 14px 10px rgba(0, 0, 0, 0.1); }
  a.underlined:before,
  .link.underlined:before {
    pointer-events: none;
    border-radius: 5px;
    content: ' ';
    display: block;
    height: 3px;
    position: absolute;
    left: -50%;
    width: 200%;
    bottom: 0px;
    background-color: #f8e379;
    transform: scale(0.5);
    transform-origin: bottom center;
    transition: left .2s ease-out, width .2s ease-out; }
  a.underlined:after,
  .link.underlined:after {
    pointer-events: none;
    border-radius: 5px;
    content: ' ';
    display: block;
    height: 3px;
    position: absolute;
    left: 50%;
    width: 00%;
    bottom: 0px;
    background-color: #fff;
    transform: scale(0.5);
    transform-origin: bottom center;
    transition: left .2s ease-out, width .2s ease-out;
    transition-delay: 0s; }
  @media (min-width: 768px) {
    a.underlined:hover, a.underlined:active, a.underlined:focus,
    .link.underlined:hover,
    .link.underlined:active,
    .link.underlined:focus {
      text-shadow: 0 6px 10px transparent; }
      a.underlined:hover:after, a.underlined:active:after, a.underlined:focus:after,
      .link.underlined:hover:after,
      .link.underlined:active:after,
      .link.underlined:focus:after {
        left: -50%;
        transition-delay: .03s;
        width: 200%; } }
  a.underlined.secondary,
  .link.underlined.secondary {
    padding-bottom: 4px;
    position: relative;
    text-shadow: 0 14px 10px rgba(0, 0, 0, 0.1); }
    a.underlined.secondary:before,
    .link.underlined.secondary:before {
      pointer-events: none;
      border-radius: 5px;
      content: ' ';
      display: block;
      height: 3px;
      position: absolute;
      left: -50%;
      width: 200%;
      bottom: 0px;
      background-color: #ee6600;
      transform: scale(0.5);
      transform-origin: bottom center;
      transition: left .2s ease-out, width .2s ease-out; }
    a.underlined.secondary:after,
    .link.underlined.secondary:after {
      pointer-events: none;
      border-radius: 5px;
      content: ' ';
      display: block;
      height: 3px;
      position: absolute;
      left: 50%;
      width: 00%;
      bottom: 0px;
      background-color: #fff;
      transform: scale(0.5);
      transform-origin: bottom center;
      transition: left .2s ease-out, width .2s ease-out;
      transition-delay: 0s; }
    @media (min-width: 768px) {
      a.underlined.secondary:hover, a.underlined.secondary:active, a.underlined.secondary:focus,
      .link.underlined.secondary:hover,
      .link.underlined.secondary:active,
      .link.underlined.secondary:focus {
        text-shadow: 0 6px 10px transparent; }
        a.underlined.secondary:hover:after, a.underlined.secondary:active:after, a.underlined.secondary:focus:after,
        .link.underlined.secondary:hover:after,
        .link.underlined.secondary:active:after,
        .link.underlined.secondary:focus:after {
          left: -50%;
          transition-delay: .03s;
          width: 200%; } }
  a.underlined.danger,
  .link.underlined.danger {
    padding-bottom: 4px;
    position: relative;
    text-shadow: 0 14px 10px rgba(0, 0, 0, 0.1); }
    a.underlined.danger:before,
    .link.underlined.danger:before {
      pointer-events: none;
      border-radius: 5px;
      content: ' ';
      display: block;
      height: 3px;
      position: absolute;
      left: -50%;
      width: 200%;
      bottom: 0px;
      background-color: #ff0000;
      transform: scale(0.5);
      transform-origin: bottom center;
      transition: left .2s ease-out, width .2s ease-out; }
    a.underlined.danger:after,
    .link.underlined.danger:after {
      pointer-events: none;
      border-radius: 5px;
      content: ' ';
      display: block;
      height: 3px;
      position: absolute;
      left: 50%;
      width: 00%;
      bottom: 0px;
      background-color: #fff;
      transform: scale(0.5);
      transform-origin: bottom center;
      transition: left .2s ease-out, width .2s ease-out;
      transition-delay: 0s; }
    @media (min-width: 768px) {
      a.underlined.danger:hover, a.underlined.danger:active, a.underlined.danger:focus,
      .link.underlined.danger:hover,
      .link.underlined.danger:active,
      .link.underlined.danger:focus {
        text-shadow: 0 6px 10px transparent; }
        a.underlined.danger:hover:after, a.underlined.danger:active:after, a.underlined.danger:focus:after,
        .link.underlined.danger:hover:after,
        .link.underlined.danger:active:after,
        .link.underlined.danger:focus:after {
          left: -50%;
          transition-delay: .03s;
          width: 200%; } }

span.br {
  display: block; }

@media (min-width: 768px) {
  span.br-sm {
    display: block; } }

.link {
  font-size: 1rem;
  cursor: pointer; }

.underlined {
  cursor: pointer;
  padding-bottom: 4px;
  position: relative;
  text-shadow: 0 14px 10px rgba(0, 0, 0, 0.1); }
  .underlined:before {
    pointer-events: none;
    border-radius: 5px;
    content: ' ';
    display: block;
    height: 3px;
    position: absolute;
    left: -50%;
    width: 200%;
    bottom: 0px;
    background-color: #f8e379;
    transform: scale(0.5);
    transform-origin: bottom center;
    transition: left .2s ease-out, width .2s ease-out; }
  .underlined:after {
    pointer-events: none;
    border-radius: 5px;
    content: ' ';
    display: block;
    height: 3px;
    position: absolute;
    left: 50%;
    width: 00%;
    bottom: 0px;
    background-color: #fff;
    transform: scale(0.5);
    transform-origin: bottom center;
    transition: left .2s ease-out, width .2s ease-out;
    transition-delay: 0s; }
  @media (min-width: 768px) {
    .underlined:hover, .underlined:active, .underlined:focus {
      text-shadow: 0 6px 10px transparent; }
      .underlined:hover:after, .underlined:active:after, .underlined:focus:after {
        left: -50%;
        transition-delay: .03s;
        width: 200%; } }

h1 {
  font-size: 36px;
  line-height: 52px;
  margin: 0 auto 12px; }

h2 {
  margin-bottom: 30px;
  font-size: 32px;
  line-height: 46px; }

h3 {
  font-size: 18px;
  line-height: 24px; }

.subtitle {
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 12px; }

.font-base {
  font-size: 1rem; }

/*****
/* Header
/******/
header#header {
  padding-top: 30px;
  padding-bottom: 30px; }
  header#header.inactive {
    display: none; }
  header#header .brand {
    padding: 12px 0; }
    header#header .brand img {
      max-height: 36px;
      max-width: 150px; }
  header#header #user-menu {
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;
    padding-left: 30px;
    padding-top: 0; }
  header#header .greeting {
    margin-right: auto; }
  header#header nav > ul.nav > li {
    display: block;
    float: left; }
    header#header nav > ul.nav > li + li {
      position: relative;
      margin-left: 10px; }
    header#header nav > ul.nav > li > a {
      padding: 6px 10px; }
      header#header nav > ul.nav > li > a.active {
        border-bottom: 1px solid #f8e379; }
  header#header .avatar {
    float: right;
    margin-left: 14px; }
    header#header .avatar .photo {
      border: 1px solid #fff;
      padding: 2px;
      height: 45px;
      width: 45px; }
  header#header .menu-bars .menu-bar {
    background: #fff; }
  @media (max-width: 767.8px) {
    header#header {
      padding: 16px 24px 16px 30px; }
      header#header #user-menu.logged-in {
        float: right; }
      header#header.logged-in .brand {
        position: absolute;
        left: 50%;
        margin-left: -60px;
        width: 120px; }
        header#header.logged-in .brand img {
          max-width: 100%;
          height: auto; }
      header#header .avatar {
        margin-left: 0;
        display: inline-block; }
        header#header .avatar .photo {
          border: 1px solid #f8e379;
          padding: 3px;
          height: 50px;
          width: 50px; } }

.menu-view #user-menu {
  padding: 20px 20px calc(env(safe-area-inset-bottom) + 40px);
  font-size: 17px;
  font-weight: 600; }
  .menu-view #user-menu .avatar {
    max-width: 90px;
    max-height: 90px;
    margin: 0 auto 10px;
    border: 3px solid #f8e379;
    border-radius: 50%; }
    .menu-view #user-menu .avatar .photo {
      margin: 2px; }
  .menu-view #user-menu nav {
    flex-direction: column; }
    .menu-view #user-menu nav ul.nav {
      width: 100%;
      flex-direction: column; }
      .menu-view #user-menu nav ul.nav li {
        width: 100%; }

.main-view {
  transition: filter 0.4s, transform 0.4s;
  display: flex;
  flex: 0 1 auto;
  flex-direction: column; }

.page-view {
  position: relative;
  z-index: 3;
  margin-top: 105px; }
  .page-view > .inner {
    margin-top: 40px; }

.landing-page-container {
  top: 0;
  left: 0;
  width: 100vw; }

.home-view {
  width: 100vw;
  height: 100vh; }

.landing-view {
  position: relative;
  z-index: 3; }
  .landing-view .bg-darker-green {
    background: #0b2733; }
  .landing-view .bg-dark-green {
    background: #17323d; }
  .landing-view .section-how-it-works {
    background: #e2e0d9;
    color: #17323d; }
    .landing-view .section-how-it-works p {
      color: #17323d; }
  .landing-view .divider {
    margin: 100px 0; }
  .landing-view .cta-wrapper {
    padding-bottom: env(safe-area-inset-bottom);
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0b2733+0,0b2733+100&0+0,1+81 */
    background: -moz-linear-gradient(top, rgba(11, 39, 51, 0) 0%, #0b2733 81%, #0b2733 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(11, 39, 51, 0) 0%, #0b2733 81%, #0b2733 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(11, 39, 51, 0) 0%, #0b2733 81%, #0b2733 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000b2733', endColorstr='#0b2733',GradientType=0 );
    /* IE6-9 */ }
  .landing-view .cta-more {
    cursor: pointer; }
    .landing-view .cta-more .cta-icon {
      pointer-events: none;
      color: #f8e379; }
  .landing-view h2 {
    text-align: center; }
  .landing-view .sample-nav {
    font-size: 16px;
    margin-bottom: 16px; }
    .landing-view .sample-nav .avatar {
      float: left;
      margin-right: 16px;
      width: 48px;
      height: 48px; }
      .landing-view .sample-nav .avatar .photo {
        border-radius: 50%;
        height: 48px;
        width: 48px;
        padding: 1px;
        border: 2px solid #505050; }
        .landing-view .sample-nav .avatar .photo img {
          border-radius: 50%; }
  .landing-view .sample {
    max-width: 600px;
    margin: 0 auto;
    background: #fff;
    padding: 30px;
    font-family: "Open Sans", sans-serif;
    color: #505050;
    font-size: 13px;
    line-height: 24px;
    border-radius: 1px;
    position: relative;
    border-radius: 6px; }
    @media (max-width: 767.8px) {
      .landing-view .sample {
        padding: 20px; }
        .landing-view .sample .draft .toolbar {
          padding-bottom: 0; } }
    .landing-view .sample p {
      font-size: 13px;
      line-height: 1.5rem; }
    .landing-view .sample.with-arrow:before {
      pointer-events: none;
      content: ' ';
      display: block;
      position: absolute;
      left: 24px;
      top: -10px;
      width: 0;
      height: 0;
      border-left: 18px solid transparent;
      border-right: 18px solid transparent;
      border-bottom: 18px solid white; }
    .landing-view .sample.fadeout-right:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 240px;
      bottom: 0;
      border-radius: 6px;
      pointer-events: none;
      z-index: 100;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
      background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
      /* IE6-9 */ }
    .landing-view .sample .question p {
      margin-left: 64px; }
      .landing-view .sample .question p.heading {
        font-weight: bold;
        margin-bottom: 0; }
    .landing-view .sample .answer {
      pointer-events: none; }
    .landing-view .sample .draft > .inner {
      padding: 0; }
    .landing-view .sample .draft .toolbar,
    .landing-view .sample .draft .form-actions {
      pointer-events: none; }
    .landing-view .sample .draft .typed-items {
      min-height: 168px; }
    .landing-view .sample .answer {
      padding-top: 16px;
      font-family: "Playfair Display", serif; }
      .landing-view .sample .answer .avatar {
        float: none;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 12px; }
      .landing-view .sample .answer .heading {
        font-size: 15px;
        margin-bottom: 8px; }
      .landing-view .sample .answer p {
        font-size: 11px;
        margin-bottom: 16px; }
    .landing-view .sample .avatar {
      float: left;
      margin-right: 16px;
      width: 48px;
      height: 48px; }
      .landing-view .sample .avatar .photo {
        border-radius: 50%;
        height: 48px;
        width: 48px;
        padding: 1px;
        border: 2px solid #505050; }
        .landing-view .sample .avatar .photo img {
          border-radius: 50%; }
  .landing-view .signup-cta {
    text-align: center; }

.blog-view {
  position: relative;
  margin-top: 100px;
  padding: 40px 0;
  background: #fff;
  color: #0b2733; }
  .blog-view a {
    color: #0b2733;
    font-weight: 600; }
  .blog-view .summary,
  .blog-view .richtext {
    font-family: "Open Sans", sans-serif; }

.company-view {
  position: relative;
  background: #0b2733; }
  .company-view .company-hero {
    padding-top: 40px;
    padding-bottom: 0px;
    display: grid;
    justify-content: center;
    align-items: center; }
    .company-view .company-hero h2 {
      max-width: 720px;
      margin-bottom: 0; }
  .company-view .company-intro {
    font-size: 17px;
    line-height: 32px; }
  .company-view .text-bubble {
    background: url(../images/text-bubble.png) 0 0 no-repeat transparent;
    background-size: contain;
    width: 98px;
    height: 98px;
    position: absolute;
    right: 0;
    top: 0; }
  .company-view .company-bios .info {
    display: flex;
    margin-bottom: 20px; }
  .company-view .company-bios .avatar {
    border: 2px solid #0b2733;
    padding: 2px;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    margin-right: 20px; }
    .company-view .company-bios .avatar img {
      width: 100%;
      height: auto;
      border-radius: 100%; }
  .company-view .company-bios h3 {
    font-size: 22px;
    line-height: 32px; }
  .company-view .company-bios .bio {
    font-size: 16px;
    line-height: 26px; }
  .company-view .company-bios .social {
    display: flex; }
    .company-view .company-bios .social a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      background-color: #0b2733;
      width: 24px;
      height: 24px;
      border-radius: 3px;
      font-size: 16px; }
  .company-view .story-text {
    max-width: 480px;
    margin: 0 auto;
    padding-bottom: 60px; }

.content-section {
  padding: 100px 0.5rem; }
  .content-section .container {
    position: relative; }
  .content-section.bg-blurred-dark-alt {
    background: url("../images/bg-blurred-dark-alt.jpg") 50% 50% no-repeat #0b2733;
    background-size: cover; }
  .content-section.bg-dark-green {
    background-color: #09212b; }
  .content-section.bg-dark-tan {
    background: #bfbdb7;
    color: #17323d; }
  .content-section.bg-tan {
    background: #e3e0d9;
    color: #17323d; }
  .content-section.bg-board {
    background: url(../images/bg-testimonials.jpg) 50% 100% no-repeat transparent;
    background-size: cover; }

.newsletter-view {
  position: relative;
  min-height: 100vh;
  background: #0b2733; }

.blog {
  padding-bottom: 80px; }
  .blog .thumb {
    margin-bottom: 10px;
    position: relative;
    padding-top: 66.67%;
    background: #b3b1aa;
    border-radius: 3px; }
    .blog .thumb img {
      width: 100%;
      height: auto;
      border-radius: 3px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .blog .blog-nav {
    margin-bottom: 20px; }
  .blog .detail-view {
    font-size: 1rem; }
    @media (min-width: 992px) {
      .blog .detail-view {
        display: flex; } }
    @media (min-width: 992px) {
      .blog .detail-view .image-wrapper {
        min-width: 400px;
        margin-right: 30px; } }
  .blog .list {
    display: grid;
    column-gap: 30px;
    row-gap: 40px; }
    @media (min-width: 768px) {
      .blog .list {
        grid-template-columns: 1fr 1fr; } }
    @media (min-width: 992px) {
      .blog .list {
        grid-template-columns: 1fr 1fr 1fr; }
        .blog .list .list-item:first-child {
          grid-column: 1 / span 3;
          display: flex;
          padding-bottom: 30px; }
          .blog .list .list-item:first-child .thumb {
            min-width: 56%;
            margin-right: 30px;
            margin-bottom: 0;
            padding-top: 37%; }
          .blog .list .list-item:first-child .details {
            flex-grow: 1; }
          .blog .list .list-item:first-child h3.title {
            font-size: 2.5rem;
            line-height: 3rem; } }
    .blog .list .list-item .publish-date {
      font-size: 12px;
      font-family: "Open Sans", sans-serif;
      line-height: 20px;
      margin-bottom: 4px; }
    .blog .list .list-item h3.title {
      font-size: 22px;
      line-height: 32px; }
    .blog .list .list-item .summary p {
      margin-bottom: 4px;
      line-height: 24px;
      font-size: 14px; }

.press-releases .image {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  border: 3px solid #06171e; }
  .press-releases .image img {
    width: 100%;
    height: auto; }

.press-releases .detail-view {
  font-size: 1rem;
  margin-bottom: 80px; }

.press-releases .list {
  max-width: 720px;
  margin: 0 auto; }
  .press-releases .list .list-item + .list-item {
    margin-top: 30px; }
  .press-releases .list .list-item .publish-date {
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    line-height: 20px;
    margin-bottom: 4px; }
  .press-releases .list .list-item .thumb {
    width: 140px;
    border: 3px solid #06171e;
    margin-right: 30px; }
    .press-releases .list .list-item .thumb img {
      width: 100%;
      height: auto; }
  .press-releases .list .list-item .title h3 {
    margin-bottom: 12px;
    font-size: 22px;
    line-height: 32px; }
  .press-releases .list .list-item .summary p {
    margin-bottom: 4px;
    line-height: 24px;
    font-size: 14px; }

.footer {
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  padding: 40px 0;
  color: #fff;
  border-top: 5px solid #09212b;
  background: #0b2733; }
  .footer a {
    color: #fff;
    position: relative;
    opacity: 0.6;
    transition: opacity 0.3s ease; }
    .footer a + a {
      margin-left: 16px; }
  @media (max-width: 767.9px) {
    .footer .text-links {
      display: flex;
      flex-direction: column;
      width: 100%; }
      .footer .text-links a {
        margin: 0 auto;
        text-align: center;
        padding: 12px 0 !important; }
      .footer .text-links a + a {
        margin-left: auto; }
    .footer .social-links {
      padding-top: 30px;
      width: 100%;
      text-align: center; }
      .footer .social-links a {
        display: inline-block;
        padding: 12px 0 !important;
        width: 42px !important; }
      .footer .social-links a + a {
        margin-left: 0; } }
  @media (min-width: 768px) {
    .footer:hover a {
      opacity: 0.75; }
      .footer:hover a:hover {
        opacity: 1; } }

.hero-section {
  padding-top: 100px;
  height: 100vh; }
  @media (max-width: 767.8px) {
    .hero-section {
      text-align: center; } }
  @media (max-width: 767.8px) {
    .hero-section h1 {
      font-size: 2.5rem;
      line-height: 3.5rem; } }
  @media (min-width: 992px) {
    .hero-section h1 {
      margin-top: 2rem;
      font-size: 3rem;
      line-height: 4rem; } }
  @media (min-width: 1200px) {
    .hero-section h1 {
      margin-top: 2rem;
      font-size: 3rem;
      line-height: 4rem; } }
  @media (min-width: 768px) {
    .hero-section .col {
      max-width: 50%; } }
  @media (max-width: 767.8px) {
    .hero-section .col-right {
      display: none; } }
  .hero-section .hero-image {
    position: relative;
    margin-top: -10%;
    margin-bottom: 20px;
    overflow: hidden;
    padding-top: 123%; }
    @media (min-width: 992px) {
      .hero-section .hero-image {
        padding-top: 116%; } }
    @media (min-width: 1200px) {
      .hero-section .hero-image {
        padding-top: 103%; } }
    .hero-section .hero-image img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      pointer-events: none; }
    .hero-section .hero-image .shadow-box {
      position: absolute;
      padding-top: 60px;
      z-index: 2;
      left: 0;
      right: 0;
      bottom: 0; }
      .hero-section .hero-image .shadow-box .shadow {
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2), 0 0 60px rgba(11, 39, 51, 0.5) !important;
        height: 60px;
        position: absolute;
        bottom: -60px;
        left: 60px;
        right: 60px; }
  .hero-section .quote {
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0; }
  .hero-section .ask-button {
    border: 4px solid #f8e379;
    background: #fff;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    color: #000;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1rem; }
  .hero-section .divider.line {
    border-top: 1px solid #f8e379;
    margin: 20px 0; }

.landing-view .cta-more {
  cursor: pointer; }
  .landing-view .cta-more .cta-label {
    font-size: 1.2rem;
    margin-right: 8px; }
  .landing-view .cta-more .cta-icon {
    font-size: 1.4rem;
    height: 24px; }

.landing-view .landing-section {
  position: relative;
  overflow: hidden; }
  .landing-view .landing-section > .inner {
    padding: 100px 0; }
  .landing-view .landing-section h2 {
    margin-bottom: 30px;
    text-align: left; }
  .landing-view .landing-section p {
    margin-bottom: 30px; }
  .landing-view .landing-section .col-left {
    padding-right: 40px; }
  .landing-view .landing-section .col-right {
    padding-left: 40px; }
  @media (max-width: 767.8px) {
    .landing-view .landing-section .col {
      flex-basis: 100%; }
    .landing-view .landing-section .col-left,
    .landing-view .landing-section .col-right {
      padding: 0 30px; } }

.landing-view .col-right .sample-wrapper {
  margin-right: -80px; }

@media (max-width: 767.8px) {
  .landing-view .ask-card-wrapper {
    margin-right: -80px;
    margin-left: 32px; } }

.landing-view .section-how-it-works .photo-col {
  margin-left: -80px;
  margin-bottom: 30px; }
  .landing-view .section-how-it-works .photo-col img {
    border-radius: 6px; }
  .landing-view .section-how-it-works .photo-col .photo-wrapper {
    position: relative; }

.landing-view .section-how-it-works .play-button {
  width: 72px;
  height: 72px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  margin-left: -36px;
  top: 50%;
  margin-top: -36px;
  text-align: center;
  color: #17323d;
  opacity: 0.6; }
  .landing-view .section-how-it-works .play-button i {
    line-height: 72px;
    font-size: 44px; }
  @media (max-width: 767.8px) {
    .landing-view .section-how-it-works .play-button {
      top: 175px;
      margin-top: 0;
      left: auto;
      margin-left: 0;
      right: 10px; } }

.landing-view .promo-video-wrapper {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #e2e0d9;
  background: #000;
  z-index: 10;
  transform: scale(0.6);
  transform-origin: left center;
  transition: all 0.2s ease; }
  .landing-view .promo-video-wrapper video {
    width: 100%;
    max-height: 100%; }
  .landing-view .promo-video-wrapper.is-playing {
    display: block;
    pointer-events: auto;
    opacity: 1;
    transform: scale(1); }

.landing-view .ask-card-wrapper {
  position: relative; }
  .landing-view .ask-card-wrapper .ask-cta {
    cursor: pointer;
    position: absolute;
    z-index: 10;
    background: #fff;
    font-size: 26px;
    font-weight: 600;
    text-align: center;
    line-height: 66px;
    width: 74px;
    height: 74px;
    top: 50%;
    margin-top: -37px;
    left: 0;
    margin-left: -37px;
    border: 3px solid #f8e379;
    border-radius: 50%;
    color: #0b2733;
    transition: background 0.2s ease, border 0.2s ease; }
    .landing-view .ask-card-wrapper .ask-cta:hover {
      background-color: #e7eae9;
      border-color: #fff; }
    .landing-view .ask-card-wrapper .ask-cta:active {
      transform: translateY(1px); }

.landing-view .ask-card {
  background: #fff;
  border-radius: 6px;
  color: #0b2733; }
  .landing-view .ask-card .top,
  .landing-view .ask-card .bottom {
    text-align: center;
    height: 120px; }
  .landing-view .ask-card .top {
    font-size: 18px; }
    .landing-view .ask-card .top p {
      font-size: 18px;
      margin-bottom: 0; }
  .landing-view .ask-card .bottom {
    background: #e7eae9;
    border-radius: 0 0 6px 6px; }
    .landing-view .ask-card .bottom p {
      font-size: 17px;
      line-height: 26px;
      margin-bottom: 0; }

.landing-view .section-pricing {
  background: #0b2733; }
  @media (max-width: 767.8px) {
    .landing-view .section-pricing .col-left {
      order: 2; }
    .landing-view .section-pricing .col-right {
      order: 1; } }

.landing-view .section-testimonials {
  background: #e2e0d9;
  color: #17323d;
  position: relative; }
  .landing-view .section-testimonials .testimonials-wrapper {
    margin-left: -80px; }
  .landing-view .section-testimonials .col-left {
    max-width: 50%; }
    @media (max-width: 767.8px) {
      .landing-view .section-testimonials .col-left {
        margin: unset;
        max-width: unset; }
        .landing-view .section-testimonials .col-left .testimonials-wrapper {
          margin-left: 0; } }
  .landing-view .section-testimonials .testimonials {
    background: url(../images/bg-testimonials.jpg);
    background-size: cover;
    background-position: 50% 90%;
    border-radius: 6px;
    min-height: 200px;
    padding-bottom: 40px;
    color: #fff; }
    .landing-view .section-testimonials .testimonials .testimonial {
      text-align: center;
      height: 100%;
      font-size: 1rem;
      line-height: 26px; }
      .landing-view .section-testimonials .testimonials .testimonial > .inner {
        padding: 60px 80px 40px; }
        @media (max-width: 767.8px) {
          .landing-view .section-testimonials .testimonials .testimonial > .inner {
            padding: 40px 20px 40px; } }
      .landing-view .section-testimonials .testimonials .testimonial .quote {
        margin-bottom: 10px; }
      .landing-view .section-testimonials .testimonials .testimonial .name {
        opacity: 0.8; }

.loading-view {
  text-align: center;
  position: fixed;
  top: 5px;
  left: 5px;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  transition: opacity 0.3s ease-out, transform 0.3s ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  will-change: opacity; }
  .loading-view:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    background: url("../images/bg.jpg") no-repeat 50% 0% #08202c;
    background-size: cover;
    z-index: -1;
    transform: scale(1.1); }
  .loading-view.inactive {
    pointer-events: none;
    opacity: 0; }
  .loading-view .heading {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column; }
  .loading-view .message {
    font-size: 16px;
    line-height: 20px;
    margin-right: 0;
    margin-bottom: 4px;
    display: block; }
  .loading-view .brand {
    display: block;
    max-width: 160px; }
    .loading-view .brand img {
      max-width: 100%;
      width: 100%;
      height: auto; }

.loading-bar-wrapper {
  max-width: 90%;
  margin: 0 auto; }
  .loading-bar-wrapper.status-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    max-width: 100%; }
    .loading-bar-wrapper.status-bar .loading-bar {
      background: none transparent;
      margin: 0;
      width: 100%; }
    .loading-bar-wrapper.status-bar .radial-shadow {
      display: none; }

.loading-bar {
  height: 3px;
  margin: 10px auto;
  background: rgba(255, 255, 255, 0.3);
  width: 700px;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 2px; }
  .loading-bar .loading-bar-progress {
    background-color: #fcf3c7;
    height: 3px;
    animation: loadingBar 2s infinite;
    margin: 0 auto;
    width: 0; }

@keyframes loadingBar {
  80% {
    width: 100%;
    opacity: 1; }
  100% {
    width: 100%;
    opacity: 0; } }

@media (min-width: 768px) {
  .radial-shadow {
    position: relative;
    pointer-events: none; }
    .radial-shadow:after {
      pointer-events: none;
      content: ' ';
      display: block;
      height: 20px;
      background: black;
      width: 90%;
      left: 5%;
      position: absolute;
      bottom: -12px;
      border-radius: 50%;
      opacity: 0.8;
      z-index: -1;
      -webkit-filter: blur(12px);
      -moz-filter: blur(12px);
      -o-filter: blur(12px);
      -ms-filter: blur(12px);
      filter: blur(12px);
      margin: 0 auto; }
    .radial-shadow.top:after {
      bottom: auto;
      top: -10px;
      left: 10%;
      width: 80%; }
    .radial-shadow.back {
      overflow: hidden;
      height: 80px;
      margin-top: -80px;
      position: absolute;
      width: 100%; }
      .radial-shadow.back:after {
        z-index: 1; }
    .radial-shadow.front {
      overflow: hidden;
      height: 80px;
      margin-bottom: -80px; }
      .radial-shadow.front:after {
        z-index: 1; }
    .radial-shadow.divider {
      margin-top: 40px;
      margin-bottom: -60px; } }

.divider {
  margin: 40px 0; }

.form-group {
  position: relative; }
  .form-group label {
    color: #fff;
    font-size: 17px;
    line-height: 20px;
    font-weight: 600;
    transition: opacity 0.3s;
    margin-bottom: 0; }
  .form-group .form-input {
    width: 100%;
    border-width: 0 0 2px;
    border-color: #f8e379;
    border-radius: 1px;
    padding: 8px 12px;
    height: auto;
    color: #505050;
    transition: border 0.3s; }
    .form-group .form-input:hover, .form-group .form-input:active, .form-group .form-input:focus {
      outline: none;
      box-shadow: none; }
      .form-group .form-input:hover:not(.disabled), .form-group .form-input:active:not(.disabled), .form-group .form-input:focus:not(.disabled) {
        outline: none;
        box-shadow: none; }
        .form-group .form-input:hover:not(.disabled):active, .form-group .form-input:hover:not(.disabled):focus, .form-group .form-input:active:not(.disabled):active, .form-group .form-input:active:not(.disabled):focus, .form-group .form-input:focus:not(.disabled):active, .form-group .form-input:focus:not(.disabled):focus {
          outline: none;
          box-shadow: none; }
    .form-group .form-input:focus {
      border-color: #505050; }
    .form-group .form-input.is-invalid {
      border-bottom-color: #ff0000; }
  .form-group .form-control {
    background: none;
    border-width: 0 0 2px;
    border-color: #f8e379;
    border-radius: 1px;
    padding: 8px 0;
    height: auto;
    color: #fff;
    transition: border 0.3s;
    border-bottom: 2px solid #f8e379;
    position: relative; }
    .form-group .form-control:hover, .form-group .form-control:active, .form-group .form-control:focus {
      background: none;
      outline: none;
      box-shadow: none; }
      .form-group .form-control:hover:not(.disabled), .form-group .form-control:active:not(.disabled), .form-group .form-control:focus:not(.disabled) {
        background: none;
        outline: none;
        box-shadow: none; }
        .form-group .form-control:hover:not(.disabled):active, .form-group .form-control:hover:not(.disabled):focus, .form-group .form-control:active:not(.disabled):active, .form-group .form-control:active:not(.disabled):focus, .form-group .form-control:focus:not(.disabled):active, .form-group .form-control:focus:not(.disabled):focus {
          background: none;
          outline: none;
          box-shadow: none; }
    .form-group .form-control:focus {
      border-color: #fff; }
    .form-group .form-control.is-invalid {
      border-bottom-color: #ff0000; }
    .form-group .form-control:after {
      content: ' ';
      display: block;
      height: 2px;
      background-color: #fff;
      position: absolute;
      left: 50%;
      width: 0;
      bottom: -2px;
      z-index: 2;
      transition: left .3s, width .3s;
      pointer-events: none; }
    @media (min-width: 768px) {
      .form-group .form-control:focus:after {
        left: 0;
        width: 100%; } }
  .form-group select.form-control {
    -webkit-appearance: none;
    -webkit-border-radius: 1px;
    padding: 8px 0; }
    .form-group select.form-control option {
      color: #fff;
      background: #1e3740; }
  .form-group textarea.form-control {
    resize: none;
    max-height: 240px; }
  .form-group:focus-within .textarea-container {
    border-bottom-color: #fff; }
  .form-group:focus-within label:not(.custom-control-label) {
    opacity: 0.5; }

.modal .form-group .invalid-feedback {
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  padding: 0 0 0 12px;
  position: relative;
  margin-top: 12px; }
  .modal .form-group .invalid-feedback.inline-error {
    position: absolute;
    top: 0;
    right: 0;
    width: auto;
    float: right; }
  .modal .form-group .invalid-feedback:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 5px;
    background: #ff0000;
    width: 5px;
    height: 5px;
    border-radius: 50%; }

.btn {
  font-size: 20px;
  line-height: 28px;
  transition: opacity 0.3s; }
  .btn.btn-primary {
    border: 0 none;
    background: none;
    padding: 0; }
    .btn.btn-primary:hover, .btn.btn-primary:active, .btn.btn-primary:focus {
      background: none;
      outline: none;
      box-shadow: none; }
      .btn.btn-primary:hover:not(.disabled), .btn.btn-primary:active:not(.disabled), .btn.btn-primary:focus:not(.disabled) {
        background: none;
        outline: none;
        box-shadow: none; }
        .btn.btn-primary:hover:not(.disabled):active, .btn.btn-primary:hover:not(.disabled):focus, .btn.btn-primary:active:not(.disabled):active, .btn.btn-primary:active:not(.disabled):focus, .btn.btn-primary:focus:not(.disabled):active, .btn.btn-primary:focus:not(.disabled):focus {
          background: none;
          outline: none;
          box-shadow: none;
          color: inherit; }
        .btn.btn-primary:hover:not(.disabled):active:hover, .btn.btn-primary:active:not(.disabled):active:hover, .btn.btn-primary:focus:not(.disabled):active:hover {
          transform: translateY(1px); }
      .btn.btn-primary:hover:hover, .btn.btn-primary:hover:active, .btn.btn-primary:active:hover, .btn.btn-primary:active:active, .btn.btn-primary:focus:hover, .btn.btn-primary:focus:active {
        opacity: 0.7; }
    .btn.btn-primary i {
      color: #ee6600;
      float: right;
      margin: 2px 4px 0;
      line-height: inherit; }
  .btn.btn-secondary {
    font-family: "Open Sans", sans-serif;
    background: #5aaa95;
    border: 2px solid #5aaa95;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    padding-left: 20px;
    padding-right: 20px; }
  .btn.btn-tertiary {
    font-family: "Open Sans", sans-serif;
    background: #fe5d26;
    border: 2px solid #fe5d26;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    padding-left: 20px;
    padding-right: 20px; }
  .btn.btn-outline {
    font-family: "Open Sans", sans-serif;
    background: transparent;
    border: 2px solid #fff;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    padding-left: 20px;
    padding-right: 20px; }
  .btn.btn-hidden {
    opacity: 0;
    pointer-events: none; }
  .btn.btn-white {
    background-color: #fff;
    color: #0b2733;
    font-size: 1rem; }
    .btn.btn-white.btn-lg {
      font-size: 1.2rem;
      padding-top: 10px;
      padding-bottom: 10px; }

.custom-control.custom-checkbox {
  padding-left: 0; }

.custom-checkbox .custom-control-label {
  font-size: 14px;
  line-height: 24px;
  padding-left: 30px;
  cursor: pointer; }
  .custom-checkbox .custom-control-label:focus {
    outline: none; }
    .custom-checkbox .custom-control-label:focus:before, .custom-checkbox .custom-control-label:focus:after {
      outline: none; }
  .custom-checkbox .custom-control-label:focus:before, .custom-checkbox .custom-control-label:active:before {
    background: none;
    box-shadow: none; }

.custom-checkbox .custom-control-input:disabled ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:disabled ~ .custom-control-label::after {
  opacity: 0.3; }

.custom-checkbox .custom-control-input:disabled ~ .custom-control-label {
  transform: none !important; }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background: none; }

.custom-checkbox .custom-control-label::before,
.custom-checkbox .custom-control-label::after {
  transform: scale(0.5);
  transform-origin: top left;
  width: 40px;
  height: 40px;
  left: 0;
  top: 0; }

.custom-checkbox .custom-control-label::before {
  background: none;
  border: 3px solid #f8e379;
  border-radius: 1px;
  box-shadow: none; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background: none;
  box-shadow: none; }

.custom-checkbox .custom-control-input.is-invalid ~ .custom-control-label {
  color: #fff; }
  .custom-checkbox .custom-control-input.is-invalid ~ .custom-control-label:before {
    background: none;
    box-shadow: none;
    border-color: #ff0000; }
  .custom-checkbox .custom-control-input.is-invalid ~ .custom-control-label a.underlined:before {
    background-color: #ff0000; }

.simple-question-form {
  min-width: 360px;
  max-width: 520px; }
  @media (max-width: 767.8px) {
    .simple-question-form {
      min-width: 0;
      max-width: 80%; } }
  .simple-question-form .form-actions {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    transform: translateY(5px); }
  .simple-question-form textarea.form-control {
    padding: 10px 0;
    overflow: visible;
    border: 0 none;
    transition: padding 0.1s ease-out, height 0.1s ease-out, border 0.2s ease-out;
    border-bottom: 2px solid #f8e379;
    position: relative; }
    .simple-question-form textarea.form-control:after {
      content: ' ';
      display: block;
      height: 2px;
      background-color: #fff;
      position: absolute;
      left: 50%;
      width: 0;
      bottom: -2px;
      z-index: 2;
      transition: left .3s, width .3s;
      pointer-events: none; }
    @media (min-width: 768px) {
      .simple-question-form textarea.form-control:focus:after {
        left: 0;
        width: 100%; } }
    .simple-question-form textarea.form-control::-webkit-scrollbar {
      display: none; }
  .simple-question-form.valid .form-actions {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0); }

.form-cta {
  text-align: center;
  font-size: 14px; }
  .form-cta a {
    font-weight: 600;
    margin-bottom: 20px;
    margin-right: 20px; }
  @media (max-width: 767.8px) {
    .form-cta {
      text-align: left;
      line-height: 18px; }
      .form-cta a {
        display: inline-block;
        white-space: nowrap; } }

@media (max-width: 767.8px) {
  .login-form .form-actions,
  .signup-form .form-actions {
    display: block !important; } }

.login-form .form-cta,
.signup-form .form-cta {
  text-align: left; }
  .login-form .form-cta a,
  .signup-form .form-cta a {
    float: left; }

.login-form .custom-checkbox a,
.signup-form .custom-checkbox a {
  display: inline-block; }

.terms-active .signup-form-wrapper {
  display: none; }

.terms-active .terms-wrapper {
  display: block; }

.terms-inactive .signup-form-wrapper {
  display: block; }

.terms-inactive .terms-wrapper {
  display: none; }

.modal-body h1 {
  margin-bottom: 40px; }

.modal-body li {
  margin-bottom: 16px; }

.form-actions {
  margin-top: 20px;
  position: relative; }
  .form-actions .radial-shadow {
    margin-bottom: -40px; }
  .form-actions .btn {
    white-space: nowrap;
    word-break: keep-all; }

table.table {
  font-size: 1rem; }
  table.table thead {
    font-style: italic;
    font-size: 1rem; }
    table.table thead th {
      font-weight: 400;
      border-width: 0 0 1px;
      border-style: solid;
      border-color: rgba(255, 255, 255, 0.2);
      padding: 7px 0; }
  table.table tbody td {
    border-width: 0 0 1px;
    border-style: solid;
    border-color: rgba(255, 255, 255, 0.2);
    padding: 7px 0; }

.font-sans {
  font-family: "Open Sans", sans-serif; }

.font-serif, .threads-container .header, .threads-container .header ul.nav-categories, .landing-view .ask-card-wrapper, .landing-view .ask-card-wrapper .ask-cta {
  font-family: "Playfair Display", serif; }

.color-grey {
  color: #bbb; }

.text-label {
  color: #505050;
  font-family: "Playfair Display", serif; }

.text-xxs {
  font-size: 0.7rem;
  line-height: 1.3rem; }

.text-xs {
  font-size: 0.8rem;
  line-height: 1.7rem; }

.text-sm {
  font-size: 0.9rem;
  line-height: 1.7rem; }

.text-md {
  font-size: 1.1rem;
  line-height: 1.8rem; }

.text-xl, .blog-view h3.title {
  font-size: 2.5rem;
  line-height: 3rem; }

.text-2xl {
  font-size: 3rem;
  line-height: 3.5rem; }

.text-2xl {
  font-size: 3.5rem;
  line-height: 4.25rem; }

.text-4xl {
  font-size: 4rem;
  line-height: 5rem; }

.text-small {
  font-size: 1rem; }

.text-bold {
  font-weight: 600; }

.opacity-0 {
  opacity: 0; }

.opacity-10 {
  opacity: 0.1; }

.opacity-20 {
  opacity: 0.2; }

.opacity-30 {
  opacity: 0.3; }

.opacity-40 {
  opacity: 0.4; }

.opacity-50 {
  opacity: 0.5; }

.opacity-60 {
  opacity: 0.6; }

.opacity-70 {
  opacity: 0.7; }

.opacity-80 {
  opacity: 0.8; }

.opacity-90 {
  opacity: 0.9; }

.opacity-100 {
  opacity: 1; }

match {
  background: #f8e379; }

@media (max-width: 767.8px) {
  .container {
    padding-left: 10px;
    padding-right: 10px; } }

.alerts,
.alert-wrapper {
  pointer-events: none; }

.alert {
  pointer-events: auto;
  background: rgba(1, 14, 18, 0.5);
  padding: 8px 20px 8px 20px; }
  .alert.alert-success {
    border: none;
    color: #fff;
    position: relative;
    font-size: 14px;
    line-height: 16px;
    padding-left: 40px; }
    .alert.alert-success:before {
      content: 'checkmark';
      font-family: 'Material Icons';
      display: inline;
      position: absolute;
      left: 12px;
      top: 7px;
      width: 20px;
      text-align: left; }
  .alert.alert-danger, .alert.alert-error {
    border: none;
    color: #fff;
    position: relative;
    font-size: 14px;
    line-height: 16px;
    padding-left: 24px;
    float: right; }
    .alert.alert-danger:before, .alert.alert-error:before {
      content: ' ';
      display: block;
      position: absolute;
      left: 10px;
      top: 14px;
      background: #ff0000;
      width: 5px;
      height: 5px;
      border-radius: 50%; }

.flex-grow-2 {
  flex-grow: 2; }

.rc-anchor-light.rc-anchor-normal {
  display: none !important; }

.close-bars {
  height: 28px;
  padding-top: 12px; }
  .close-bars .close-bar {
    height: 2px;
    width: 26px;
    background: #f8e379;
    border-radius: 5px;
    transform-origin: center;
    margin-left: auto;
    margin-right: auto; }
    .close-bars .close-bar:nth-child(1) {
      transform: rotate(45deg); }
    .close-bars .close-bar:nth-child(2) {
      margin-top: -2px;
      transform: rotate(-45deg); }
    .close-bars .close-bar:before {
      content: ' ';
      display: block;
      height: 2px;
      width: 0px;
      background-color: #fff;
      margin-left: auto;
      margin-right: auto;
      transition: width 0.2s ease-in-out, margin 0.2s ease-in-out;
      border-radius: 5px; }
  @media (min-width: 768px) {
    .close-bars:hover .close-bar:before {
      width: 100%; } }

.not-found-view {
  background: #17323d;
  background: url(../images/bg-testimonials.jpg);
  background-size: cover;
  background-position: 50% 90%;
  min-height: 400px;
  text-align: center;
  padding-top: 100px; }
  .not-found-view .page-header h1 {
    text-align: center;
    margin-bottom: 40px; }

ul.tag-list {
  padding: 0; }
  ul.tag-list > li {
    list-style: none;
    white-space: nowrap;
    font-size: 0.8rem;
    line-height: 1.1rem;
    font-family: "Open Sans", sans-serif;
    background: rgba(0, 0, 0, 0.05);
    padding: 4px 12px;
    border-radius: 50px;
    margin: 0 8px 8px 0;
    color: #505050; }
    ul.tag-list > li i {
      position: relative;
      top: 2px;
      color: #0b2733;
      margin-right: 4px; }
  ul.tag-list.dark > li {
    color: #ddd;
    background-color: rgba(255, 255, 255, 0.1); }

.page-view.notifications-view {
  background: url("../images/bg-blurred.jpg") no-repeat 50% 0% #08202c;
  position: fixed;
  top: 0;
  bottom: 0;
  padding: 0;
  margin: 0; }
